var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "app-header",
      style: { top: _vm.top_offset + "px" },
      attrs: { "data-testid": "app-header-component" },
    },
    [
      !_vm.auth.user.profile.created_by_reseller || _vm.isSimpSocial
        ? _c("terms-and-conditions", {
            attrs: { "data-testid": "terms-and-conditions" },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("workflow-creator", { ref: "workflow_creator" }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "navbar navbar-toggleable-sm flex-row align-items-center justify-content-center bg-white",
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _vm.auth.user &&
          _vm.auth.user.profile &&
          !_vm.auth.user.profile.is_reseller
            ? _c(
                "a",
                {
                  staticClass: "d-none d-lg-block mr-2 text-lg text-blackish",
                  attrs: {
                    "data-testid": "toggle-sidebar",
                    title: _vm.sidebar_folded
                      ? "Normal Layout"
                      : "Expanded Layout",
                  },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.toggleSidebar.apply(null, arguments)
                    },
                  },
                },
                [_c("i", { staticClass: "fa fa-bars" })]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "mb-0 h5 no-wrap",
              attrs: { id: "page-title", "data-testid": "page-title" },
            },
            [
              _vm.isLargeEnough
                ? [
                    _vm._l(_vm.validRoutes, function (route, idx) {
                      return [
                        idx != 1
                          ? [
                              _c(
                                "router-link",
                                { attrs: { to: { name: route.name } } },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        _vm.applyCustomPageTitle(
                                          route.name.capitalize()
                                        )
                                      ) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              idx < _vm.validRoutes.length - 1 &&
                              _vm.currentRouteName.length > 0 &&
                              idx != 0
                                ? _c(
                                    "span",
                                    { staticClass: "ml-1 mr-1 _100" },
                                    [
                                      _vm._v(
                                        "\n                            >\n                        "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          : _vm._e(),
                      ]
                    }),
                    _vm._v(" "),
                    _vm.pageTitle
                      ? [
                          _c("span", { staticClass: "ml-1 mr-1 _100" }, [
                            _vm._v(
                              "\n                        >\n                    "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.pageTitle) +
                                "\n                    "
                            ),
                          ]),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.contact_name
                      ? [
                          _c("span", { staticClass: "ml-1 mr-1 _100" }, [
                            _vm._v(
                              "\n                        >\n                    "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.contact_name) +
                                "\n                    "
                            ),
                          ]),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.currentSelectedTeamName
                      ? [
                          _c("span", { staticClass: "ml-1 mr-1 _100" }, [
                            _vm._v(
                              "\n                        >\n                    "
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                effect: "dark",
                                placement: "bottom-start",
                                content: _vm.currentSelectedTeamName,
                              },
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("truncateText")(
                                      _vm.currentSelectedTeamName
                                    )
                                  )
                                ),
                              ]),
                            ]
                          ),
                        ]
                      : _vm._e(),
                  ]
                : [
                    _vm.lastRoute && _vm.lastRoute.name && !_vm.contact_name
                      ? _c(
                          "router-link",
                          { attrs: { to: { name: _vm.lastRoute.name } } },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  _vm.applyCustomPageTitle(
                                    _vm.lastRoute.name.capitalize()
                                  )
                                ) +
                                "\n                "
                            ),
                          ]
                        )
                      : [
                          _c("span", [
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.contact_name) +
                                "\n                    "
                            ),
                          ]),
                        ],
                  ],
            ],
            2
          ),
          _vm._v(" "),
          _c("div", {
            staticClass: "collapse navbar-collapse",
            attrs: { "data-testid": "navabar-collapse", id: "collapse" },
          }),
          _vm._v(" "),
          _c(
            "ul",
            {
              class: {
                nav: true,
                "navbar-nav": true,
                "ml-auto": true,
                "flex-row": true,
                "no-select": true,
              },
              attrs: { "data-testid": "navbar-right" },
            },
            [
              _vm.isLargeEnough
                ? _c(
                    "li",
                    {
                      staticClass: "nav-item d-flex align-items-center mr-2",
                      attrs: { "data-testid": "navabar-right-li" },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "nav-link" },
                        [
                          _vm.showAvaPromotionButton
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "default-app-button btn btn-xs",
                                  style: {
                                    border: !_vm.isAiEngineEnabled
                                      ? "2px solid #C4183C"
                                      : "",
                                  },
                                  attrs: { size: "medium", plain: "" },
                                  on: {
                                    click: function ($event) {
                                      _vm.current_company
                                        ?.transcription_settings
                                        ?.call_transcription_enabled
                                        ? (_vm.showInfoBox = true)
                                        : null
                                    },
                                  },
                                },
                                [
                                  _c("sparkle-icon", {
                                    attrs: {
                                      width: "16",
                                      height: "16",
                                      color: _vm.isAiEngineEnabled
                                        ? "#9333EA"
                                        : "#C4183C",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.avaPromotionButtonLabel
                                      ),
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isLargeEnough
                ? _c(
                    "li",
                    {
                      staticClass: "nav-item d-flex align-items-center mr-2",
                      attrs: { "data-testid": "navabar-right-li" },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "nav-link" },
                        [
                          _vm.auth.user.profile
                            ? _c("talk-bridge", {
                                attrs: { "data-testid": "li-talk-bridge" },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.usage &&
              _vm.isLargeEnough &&
              !_vm.isMobileSize &&
              _vm.hasRole("Billing Admin") &&
              !_vm.isTrial &&
              !_vm.isSimpSocial
                ? _c(
                    "li",
                    { staticClass: "nav-item d-flex align-items-center mr-2" },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "nav-link",
                          attrs: { "data-testid": "navabar-right-li" },
                        },
                        [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: "Account",
                                  query: { tab: "billing" },
                                },
                              },
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-xs btn-outline _400",
                                  style: {
                                    color: "#ffffff",
                                    "background-color": _vm.creditColor,
                                  },
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(
                                          _vm._f("toCurrency")(
                                            _vm._f("fixRounding")(
                                              _vm.usage.credits
                                            )
                                          )
                                        ) +
                                        "\n                            "
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.whitelabel && !_vm.loading_whitelabel && !_vm.isMobileSize
                ? _c(
                    "li",
                    {
                      staticClass:
                        "nav-item d-flex align-items-center dropdown pos-stc-xs mr-2",
                      attrs: { "data-testid": "navabar-right-li" },
                    },
                    [
                      _c(
                        "a",
                        {
                          ref: "helpMenu",
                          staticClass: "nav-link",
                          attrs: {
                            "data-toggle": "dropdown",
                            "data-tour-step": "2",
                            "data-testid": "question-circle-icon-a",
                          },
                        },
                        [
                          _c("i", {
                            staticClass:
                              "fa fa-question-circle text-2x text-danger",
                            staticStyle: { "vertical-align": "-9px" },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c("HelpDropdown", {
                        attrs: {
                          "csm-info": _vm.csmInfo,
                          "debugging-info": _vm.debuggingInfo,
                          "is-self-served-account":
                            _vm.auth.isSelfServedAccount(),
                          "role-name": _vm.roleName,
                          "sales-rep-info": _vm.salesRepInfo,
                          "service-code": _vm.serviceCode,
                          "show-csm-info":
                            _vm.hasCsm &&
                            !_vm.csmInfo.internal &&
                            !_vm.isTrial &&
                            !_vm.isSimpSocial,
                          "show-order-training-button": !_vm.isSimpSocial,
                          "show-request-solution-engineer-button":
                            (_vm.auth.isSelfServedAccount() ||
                              _vm.csmInfo.internal ||
                              _vm.salesRepInfo.internal) &&
                            !_vm.isSimpSocial,
                          "show-sales-rep-info":
                            _vm.hasSalesRep &&
                            !_vm.salesRepInfo.internal &&
                            _vm.isTrial &&
                            !_vm.isSimpSocial,
                          "show-setup-guide": !_vm.isReseller,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "li",
                {
                  staticClass: "nav-item dropdown",
                  class: { "bg-dark": _vm.is_impersonated },
                  attrs: { "data-testid": "navabar-right-li" },
                },
                [
                  _c(
                    "a",
                    {
                      ref: "mainMenu",
                      class: {
                        "nav-link": true,
                        "dropdown-toggle": true,
                        "text-ellipsis": true,
                        "text-white": _vm.is_impersonated,
                      },
                      attrs: {
                        "data-toggle": "dropdown",
                        "data-flip": "false",
                        "data-testid": "user-logged-in",
                        "data-tour-step": "3",
                      },
                    },
                    [
                      _vm.auth.user.profile
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "profile-info d-flex align-items-center pl-1",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "d-flex flex-column ml-2",
                                  staticStyle: { "line-height": "1.2rem" },
                                },
                                [
                                  _c(
                                    "small",
                                    {
                                      staticClass: "dropdown-label",
                                      class: {
                                        "text-white": _vm.is_impersonated,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(_vm.auth.user.profile.name) +
                                          "\n                            "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.current_company
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "company-name _600",
                                          class: {
                                            "text-white": _vm.is_impersonated,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(_vm.current_company.name) +
                                              "\n                            "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                              _vm._v(" "),
                              _c("i", { staticClass: "material-icons ml-2" }, [
                                _vm._v("keyboard_arrow_down"),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "dropdown-menu dropdown-menu-right dropdown-menu-overlay dropdown-menu-scale pull-right",
                    },
                    [
                      _vm.auth.user.profile
                        ? _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col-12" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "dropdown-item",
                                    attrs: {
                                      to: {
                                        name: "User Dialog",
                                        params: {
                                          user_id: _vm.auth.user.profile.id,
                                        },
                                      },
                                    },
                                  },
                                  [
                                    _c("i", { staticClass: "material-icons" }, [
                                      _vm._v("person"),
                                    ]),
                                    _vm._v(
                                      "\n                                Profile Settings\n                            "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.hasRole(["Company Agent", "Company Admin"]) &&
                      _vm.auth.user.profile &&
                      !_vm.auth.user.profile.is_reseller
                        ? _c("div", { staticClass: "row nav-padding" }, [
                            _c(
                              "div",
                              {
                                staticClass: "dropdown-item",
                                attrs: { "data-testid": "sleep-mode" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                  },
                                },
                              },
                              [
                                _c(
                                  "i",
                                  {
                                    staticClass: "material-icons fa-rotate-180",
                                  },
                                  [_vm._v("brightness_2")]
                                ),
                                _vm._v(" "),
                                _c("span", [_vm._v("Sleep Mode")]),
                                _vm._v(" "),
                                _c("el-switch", {
                                  staticClass: "ml-2 pull-right",
                                  attrs: { "active-color": "#00a344" },
                                  on: { change: _vm.changeSleepMode },
                                  model: {
                                    value: _vm.auth.user.profile.sleep_mode,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.auth.user.profile,
                                        "sleep_mode",
                                        $$v
                                      )
                                    },
                                    expression: "auth.user.profile.sleep_mode",
                                  },
                                }),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "dropdown-divider" }),
                      _vm._v(" "),
                      !_vm.loading_whitelabel &&
                      !_vm.whitelabel &&
                      !_vm.isResold
                        ? _c("div", { staticClass: "row" }, [_vm._m(1)])
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.loading_whitelabel && !_vm.whitelabel
                        ? _c("div", { staticClass: "row" }, [_vm._m(2)])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "dropdown-divider" }),
                      _vm._v(" "),
                      !_vm.loading_whitelabel && !_vm.whitelabel
                        ? _c("div", { staticClass: "row" }, [_vm._m(3)])
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.loading_whitelabel && !_vm.whitelabel
                        ? _c("div", { staticClass: "row" }, [_vm._m(4)])
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.loading_whitelabel && !_vm.whitelabel
                        ? _c("div", { staticClass: "row" }, [_vm._m(5)])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.auth.user.profile.has_multiple_access
                        ? _c("div", { staticClass: "dropdown-divider" })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.auth.user.profile.has_multiple_access
                        ? [
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                { staticClass: "col-12" },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "dropdown-item",
                                      attrs: {
                                        to: { name: "Account Selector" },
                                      },
                                    },
                                    [
                                      _c("i", { staticClass: "fas fa-list" }),
                                      _vm._v(
                                        "\n                                    Select Account\n                                "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "dropdown-divider" }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "row",
                          attrs: { "data-testid": "logout-row" },
                          on: {
                            click: function ($event) {
                              return _vm.noClose($event)
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "col-12" }, [
                            _c(
                              "a",
                              {
                                staticClass: "dropdown-item text-danger",
                                attrs: { "data-testid": "logout-a" },
                                on: {
                                  click: function ($event) {
                                    return _vm.proceedToLogout()
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "material-icons" }, [
                                  _vm._v("power_settings_new"),
                                ]),
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.is_impersonated
                                        ? "Stop impersonating"
                                        : "Sign out"
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ],
                    2
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c("aloai-promotion-dialog", {
        attrs: { dialogVisible: _vm.showInfoBox },
        on: {
          "update:dialogVisible": function ($event) {
            _vm.showInfoBox = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "d-lg-none mr-2",
        attrs: {
          "data-toggle": "modal",
          "data-target": "#aside",
          "data-testid": "open-sidebar",
        },
      },
      [_c("i", { staticClass: "material-icons" }, [_vm._v("")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12" }, [
      _c(
        "a",
        {
          staticClass: "dropdown-item",
          attrs: {
            href: "https://aloware.com/partnerships/",
            "data-testid": "my-referral-link-a",
            target: "_blank",
          },
        },
        [
          _c("i", { staticClass: "fas fa-link" }),
          _vm._v(
            "\n                                My Referral Link\n                            "
          ),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12" }, [
      _c(
        "a",
        {
          staticClass: "dropdown-item",
          attrs: {
            href: "https://meetings.hubspot.com/alwr/aloware-demo",
            "data-testid": "book-a-demo-a",
            target: "_blank",
          },
        },
        [
          _c("i", { staticClass: "material-icons" }, [
            _vm._v("perm_contact_calendar"),
          ]),
          _vm._v(
            "\n                                Book a Demo\n                            "
          ),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12" }, [
      _c(
        "a",
        {
          staticClass: "dropdown-item",
          attrs: {
            href: "https://aloware.com/apps",
            "data-testid": "desktop-apps-a",
            target: "_blank",
          },
        },
        [
          _c("i", { staticClass: "material-icons" }, [_vm._v("desktop_mac")]),
          _vm._v(
            "\n                                Desktop Apps\n                            "
          ),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12" }, [
      _c(
        "a",
        {
          staticClass: "dropdown-item",
          attrs: {
            href: "https://aloware.com/apps",
            "data-testid": "mobile-apps-a",
            target: "_blank",
          },
        },
        [
          _c("i", { staticClass: "material-icons" }, [_vm._v("devices")]),
          _vm._v(
            "\n                                Mobile Apps\n                            "
          ),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12" }, [
      _c(
        "a",
        {
          staticClass: "dropdown-item",
          attrs: {
            href: "https://chrome.google.com/webstore/detail/aloware-click-to-call/kmchiimcbhaaadgpnelddelgiaeahobi?hl=en-US",
            "data-testid": "chrome-ext-a",
            target: "_blank",
          },
        },
        [
          _c("i", { staticClass: "fab fa-chrome" }),
          _vm._v(
            "\n                                Chrome Ext. (CTI)\n                            "
          ),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }