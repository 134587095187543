<template>
    <div>
        <el-table class="w-full"
                  row-key="id"
                  :data="botsData"
                  :expand-row-keys="expandableBotsId"
                  stripe>
            <el-table-column type="expand">
                <template v-slot="scope">
                    <div class="row align-items-top">
                        <div class="col-md-6">
                            <span class="text-greyish break-word">
                                <span>{{ scope.row.description ?? '-' }}</span>
                            </span>
                        </div>
                        <div class="col-md-6 b-l">
                            <p>
                                <b>Created By:</b> <span>{{ scope.row.created_by ? (scope.row.created_by.name + ' - #' + scope.row.created_by.id) : '-' }}</span>
                            </p>
                            <p>
                                <b>Last Modified By:</b> <span>{{ scope.row.modified_by ? (scope.row.modified_by.name + ' - #' + scope.row.modified_by.id) : '-' }}</span>
                            </p>
                        </div>
                    </div>
                </template>
            </el-table-column>

            <el-table-column
                label="Name"
                align="left"
                :min-width="150">
                <template v-slot="scope">
                    <div class="d-flex">
                        <el-switch
                            class="mr-4 align-self-center"
                            v-model="scope.row.enabled"
                            active-color="#00BF50"
                            @change="updateBotEnabled(scope.row)">
                        </el-switch>
                        <div>
                            <router-link :to="{
                                    name: 'AloAi: Configure an agent',
                                    params: {bot_id: scope.row.id}
                                }">
                                <div class="hover-lower-opacity">
                                    <p class="mb-0">
                                        <b>{{ scope.row.name }}</b>
                                    </p>
                                    <span>#{{ scope.row.id }}</span>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </template>
            </el-table-column>

            <el-table-column
                width="100"
                label="Type"
                align="center">
                <template v-slot="scope">
                    <span class="ml-2 d-inline-block label blue">
                        {{ scope.row.type_text }}
                    </span>
                </template>
            </el-table-column>

            <el-table-column
                width="150"
                label="Direction"
                align="left">
                <template v-slot="scope">
                    <span>{{ scope.row.direction_text }}</span>
                </template>
            </el-table-column>

            <el-table-column
                width="150"
                label="Model"
                align="left">
                <template v-slot="scope">
                    <span v-if="scope.row.type === AloAi.TYPE_TEXT">{{ scope.row.model_human_readable_text }}</span>
                    <span v-else>{{ scope.row.voice_model_human_readable_text }}</span>
                </template>
            </el-table-column>

            <el-table-column
                min-width="100"
                label="Lines"
                align="left">
                <template v-slot="scope">
                    <a>
                        <!-- One line -->
                        <span class="text-dark-greenish"
                              v-if="scope.row.campaigns.length === 1">
                            <span class="hover-lower-opacity">
                                <span>{{ scope.row.campaigns[0]?.name }}</span>
                                <br/>
                                <!-- One phone number -->
                                <span v-if="scope.row.campaigns[0].incoming_numbers.length === 1">
                                    {{ scope.row.campaigns[0]?.incoming_numbers[0]?.phone_number | fixPhone }}
                                </span>
                                <!-- Multiple phone numbers -->
                                <span v-else>
                                    {{ scope.row.campaigns[0]?.incoming_numbers.length }} Numbers
                                </span>
                            </span>
                        </span>
                        <!-- Multiple lines -->
                        <span class="text-dark-greenish"
                              v-else>
                            <router-link :to="{
                                    name: 'AloAi: Configure an agent',
                                    params: {bot_id: scope.row.id},
                                }">
                                <div class="hover-lower-opacity">
                                    <span>{{ scope.row.campaigns.length }} Lines</span>
                                </div>
                            </router-link>
                        </span>
                    </a>
                </template>
            </el-table-column>

            <el-table-column
                align="left"
                label="Status"
                :min-width="40">
                <template v-slot="scope">
                    <span v-if="scope.row.enabled"
                          class="text-green">
                        <i class="fa fa-play-circle text-success mr-1"></i>
                        <span>Running</span>
                    </span>
                    <span v-else
                          class="text-red">
                        <i class="fa fa-pause-circle text-warning mr-1"></i>
                        <span>Paused</span>
                    </span>
                </template>
            </el-table-column>

            <el-table-column
                align="right"
                :min-width="40">
                <template v-slot="scope">
                    <div class="d-flex">
                        <el-tooltip
                            class="item"
                            effect="dark"
                            content="Modify"
                            placement="top">
                            <div
                                class="hover-lower-opacity-warning ml-4 mr-4"
                                @click="handleEdit(scope.row)"
                                disabled="true">
                                <i style="font-size:17px" class="fa fa-pencil" aria-hidden="true"/>
                            </div>
                        </el-tooltip>

                        <el-tooltip
                            class="item"
                            effect="dark"
                            content="Delete"
                            placement="top">
                            <div
                                class="hover-lower-opacity-danger"
                                @click="handleDelete(scope.row)">
                                <i style="font-size:17px" class="fa fa-trash-o" aria-hidden="true"/>
                            </div>
                        </el-tooltip>
                    </div>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import axios from 'axios'
import * as AloAi from '../../constants/aloai'

const NOT_BUILD = 1
const BUILD_SUCCESS = 2
const BUILD_IN_PROGRESS = 3
const BUILD_FAILED = 4

export default {
    props: {
        bots: {
            required: false,
            default: []
        }
    },

    data() {
        return {
            NOT_BUILD,
            BUILD_SUCCESS,
            BUILD_IN_PROGRESS,
            BUILD_FAILED,
            delete_bot_visible: false,
            bot: null,
            count: 0,
            export_disabled: false,
            delete_disabled: false,
            // Todo: implement both search and pagination
            search: '',
            filters: {
                page: 1,
                size: 5,
            },
            AloAi
        }
    },

    computed: {
        expandableBotsId() {
            // check if loaded
            if (this.botsData) {
                return this.botsData.map(item => {
                    return item.id
                })
            }
        },

        // Todo: implement search logic
        botsData() {
            if (!this.bots) {
                return this.bots
            }
            return this.bots.filter(data => !this.search || data.name.toLowerCase().includes(this.search.toLowerCase()))
        }
    },

    methods: {
        updateBotEnabled(bot) {
            this.setLoading(true)

            // Get only required parameters
            let payload = _.pick(bot, ['enabled'])

            axios.patch(`/api/v1/aloai/bot/${bot.id}/toggle-active`, payload).then(res => {
                this.setLoading(false)
                this.$notify.success({
                    offset: 95,
                    title: 'AloAi Agent',
                    message: 'Agent updated successfully.',
                })
                this.$emit('bot-update', bot)
            }).catch(err => {
                console.log(err)
                this.setLoading(false)
                this.$emit('bot-update', bot)
                this.$root.handleErrors(err.response)
            })
        },

        handleEdit(row) {
            // Each row is a bot object
            this.bot = row
            // Goes to the Bot Settings page
            this.$router.push({
                name: 'AloAi: Configure an agent',
                params: {
                    bot_id: this.bot.id
                }
            })
        },

        handleDelete(row) {
            this.$emit('bot-delete', row.id)
        },

        setLoading(loading) {
            this.export_disabled = loading
            this.delete_disabled = loading
        }
    },
}
</script>
