<template>
    <div data-testid="line-details-wrapper">
        <div class="container-campaign-summary mt-5 pt-5 mb-5">
            <h2 v-if="campaign.is_fax"
                data-testid="fax-created-title">
                <i class="fa fa-check text-greenish"></i>
                Fax created
            </h2>
            <h2 v-else
                data-testid="line-created-title">
                <i class="fa fa-check text-greenish"></i>
                Line created
            </h2>
            <i v-if="campaign.is_fax"
               class="fa fa-fax is-fax finish-icon" aria-hidden="true">
            </i>
            <img v-else
                 class="finish-icon"
                 src="/assets/images/app-icons/icon-lines-grey.svg"/>

            <p class="col-10 my-0 mx-auto mb-2 text-md">
                <el-tabs class="text-left mt-4">
                    <el-tab-pane label="Review Line"
                                 data-testid="review-line-pane">
                        <div class="row p-2"
                             data-testid="name-data">
                            <div class="col-5">
                                Name:
                            </div>
                            <div class="col-7">
                                <span>{{ campaign.name }}</span>
                            </div>
                        </div>
                        <div class="row p-2"
                             data-testid="number-data">
                            <div class="col-5">
                                Your number:
                            </div>
                            <div class="col-7">
                                <span v-for="(ins, index) in campaign.incoming_numbers"
                                      :key="index"
                                      class="ins_item">
                                    <span>{{ ins.phone_number | fixPhone }}</span>
                                </span>
                            </div>
                        </div>
                        <div v-if="hasPermissionTo('list ring group') && !campaign.is_fax && campaign.ring_group_id"
                             class="row p-2">
                            <div class="col-5">
                                We are routing calls to:
                            </div>
                            <div class="col-7">
                                <template v-if="campaign.ring_group_id && getRingGroup(campaign.ring_group_id)">
                                    <span class="dn_item">
                                        <router-link
                                                :to="{ name: 'Ring Group Dialog', params: {ring_group_id: campaign.ring_group_id }}">
                                            <span class="text-dark-greenish _400">
                                                {{ getRingGroup(campaign.ring_group_id).name | fixName }}
                                            </span>
                                        </router-link>
                                    </span>
                                </template>
                            </div>
                        </div>
                        <div v-if="hasPermissionTo(['list user', 'list ring group']) && !campaign.is_fax && campaign.ring_group_id"
                             class="row p-2">
                            <div class="col-5">
                                User:
                            </div>
                            <div class="col-7">
                                <template v-if="campaign.ring_group_id && getRingGroup(campaign.ring_group_id)">
                                    <span v-for="user_id in getRingGroup(campaign.ring_group_id).user_ids"
                                          v-if="getUser(user_id)"
                                          :key="user_id"
                                          class="dn_item">
                                        <router-link
                                                :to="{ name: 'User Dialog', params: {user_id: user_id }}">
                                            <span class="text-dark-greenish _400">
                                                {{ getUserName(getUser(user_id)) }}
                                            </span>
                                        </router-link>
                                    </span>
                                </template>
                            </div>
                        </div>
                        <div v-if="campaign.user"
                             class="row p-2">
                            <div class="col-5">
                                User:
                            </div>
                            <div class="col-7">
                                <template v-if="campaign.user">
                                    <span class="dn_item">
                                        <router-link
                                                :to="{ name: 'User Dialog', params: {user_id: campaign.user_id }}">
                                            <span class="text-dark-greenish _400">
                                                {{ getUserName(getUser(campaign.user_id)) }}
                                            </span>
                                        </router-link>
                                    </span>
                                </template>
                            </div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="Suggested Next Steps">
                        <div class="row p-2"
                             v-if="campaign.is_fax">
                            <div class="col-12">
                                <span class="break-word">Send a fax to: <b>{{ campaign.incoming_number | fixPhone }}</b> and make sure you receive the fax in your dashboard.</span>
                            </div>
                        </div>
                        <div class="row p-2"
                             v-else>
                            <div class="col-12">
                                <span class="break-word">Give yourself a call :) Call the phone number: <b>{{ campaign.incoming_number | fixPhone }}</b> and make sure it rings you on the other end.</span>
                            </div>
                        </div>
                        <div class="row p-2">
                            <div class="col-12">
                                <span>Checkout the Activity tab:</span>
                                <router-link :to="{ name: 'Line Activity', params: {campaign_id: campaign.id }}"
                                             target="_blank">
                                    <el-button type="text"
                                               data-testid="activity-dashboard-button"
                                               class="text-dark-greenish">
                                        Go to Activity Dashboard
                                    </el-button>
                                </router-link>
                                <span v-if="!campaign.is_fax">. As soon as your calls rake in, we will fill this page up.</span>
                            </div>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </p>
            <div class="text-center">
                <el-button data-testid="close-button"
                           @click="$emit('close')">
                    Close
                </el-button>
                <router-link :to="{ name: 'Line Settings', params: {campaign_id: campaign.id }}"
                             v-if="hasPermissionTo('update campaign')">
                    <el-button type="success"
                               data-testid="setup-line-button"
                               @click="$emit('close')">
                        Set up your line
                    </el-button>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
    import auth from '../auth'
    import {
        acl_mixin,
        campaign_mixin,
        user_info_mixin
    } from '../mixins'
    import {mapState} from "vuex"
    import * as AnswerTypes from '../constants/answer-types'

    export default {
        mixins: [
            acl_mixin,
            campaign_mixin,
            user_info_mixin
        ],

        props: {
            campaign: {
                required: true
            }
        },

        computed: {
            ...mapState([
                'ring_groups'
            ]),
        },

        data() {
            return {
                auth: auth,
                AnswerTypes
            }
        },

        methods: {
            getRingGroup(id) {
                if (!id) {
                    return null
                }
                let found = this.ring_groups.find(ring_group => ring_group.id === id)
                if (found) {
                    return found
                }

                return null
            }
        }
    }
</script>
