var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    {
      ref: "agent_settings",
      staticClass: "aloai-agents",
      attrs: { rules: _vm.rules, model: _vm.bot },
    },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c(
              "el-tabs",
              {
                on: { "tab-click": _vm.handleClick },
                model: {
                  value: _vm.activeName,
                  callback: function ($$v) {
                    _vm.activeName = $$v
                  },
                  expression: "activeName",
                },
              },
              [
                _c(
                  "el-tab-pane",
                  {
                    attrs: {
                      label: "Configure",
                      name: "configure",
                      "data-tab-error": "configure",
                    },
                  },
                  [
                    _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                      _vm._v("Configure"),
                      _c("span", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.tabLabelErrorIcon(_vm.tab_errors.configure)
                          ),
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "row justify-content-center pt-3" },
                      [
                        _c(
                          "div",
                          { staticClass: "col-md-12 col-lg-10 main-col" },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { prop: "name" } },
                              [
                                _c("div", { staticClass: "form-label" }, [
                                  _c("h5", { staticClass: "text-dark" }, [
                                    _vm._v("Name"),
                                  ]),
                                  _vm._v(" "),
                                  _c("small", [
                                    _vm._v(
                                      "\n                    What name would you like to give to this agent?\n                  "
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("el-input", {
                                  on: {
                                    input: function ($event) {
                                      return _vm.preValidateForm(
                                        "agent_settings"
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.bot.name,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.bot, "name", $$v)
                                    },
                                    expression: "bot.name",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              [
                                _c("div", { staticClass: "form-label" }, [
                                  _c("h5", { staticClass: "text-dark" }, [
                                    _vm._v("Description"),
                                  ]),
                                  _vm._v(" "),
                                  _c("small", [
                                    _vm._v(
                                      " Describe the purpose of this agent. "
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("el-input", {
                                  attrs: { type: "textarea", rows: 2 },
                                  model: {
                                    value: _vm.bot.description,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.bot, "description", $$v)
                                    },
                                    expression: "bot.description",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("hr"),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              [
                                _c("div", { staticClass: "form-label" }, [
                                  _c("h5", { staticClass: "text-dark" }, [
                                    _vm._v("Agent type"),
                                  ]),
                                  _vm._v(" "),
                                  _c("small", [
                                    _vm._v(
                                      "\n                    What is the main channel of communication for this agent?\n                  "
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "w-100",
                                    on: { change: _vm.updateBotType },
                                    model: {
                                      value: _vm.bot.type,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.bot, "type", $$v)
                                      },
                                      expression: "bot.type",
                                    },
                                  },
                                  _vm._l(_vm.types, function (type) {
                                    return _c("el-option", {
                                      key: type.value,
                                      attrs: {
                                        label: type.name,
                                        value: type.value,
                                        disabled: _vm.lockVoiceOptions(type),
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.bot.type == _vm.AloAi.TYPE_TEXT
                              ? _c(
                                  "el-form-item",
                                  [
                                    _c("div", { staticClass: "form-label" }, [
                                      _c("h5", { staticClass: "text-dark" }, [
                                        _vm._v("Direction"),
                                      ]),
                                      _vm._v(" "),
                                      _c("small", [
                                        _vm._v(
                                          "\n                    Will this agent start the conversations or wait for your\n                    contacts to reach out first?\n                  "
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "el-select",
                                      {
                                        staticClass: "w-100",
                                        model: {
                                          value: _vm.bot.direction,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.bot, "direction", $$v)
                                          },
                                          expression: "bot.direction",
                                        },
                                      },
                                      _vm._l(
                                        _vm.directions,
                                        function (direction) {
                                          return _c("el-option", {
                                            key: direction.value,
                                            attrs: {
                                              label: direction.name,
                                              value: direction.value,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("hr"),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              [
                                _c("div", { staticClass: "form-label" }, [
                                  _c("h5", { staticClass: "text-dark" }, [
                                    _vm._v("AI model"),
                                  ]),
                                  _vm._v(" "),
                                  _c("small", [
                                    _vm._v(
                                      "\n                    Choose the AI model the agent needs to operate on. Opt for\n                    speed or depth to suit your agent's role.\n                  "
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _vm.bot.type == _vm.AloAi.TYPE_TEXT
                                  ? _c(
                                      "el-select",
                                      {
                                        staticClass: "w-100",
                                        model: {
                                          value: _vm.bot.model,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.bot, "model", $$v)
                                          },
                                          expression: "bot.model",
                                        },
                                      },
                                      _vm._l(_vm.models, function (model) {
                                        return _c("el-option", {
                                          key: model.value,
                                          attrs: {
                                            label: model.name,
                                            value: model.value,
                                          },
                                        })
                                      }),
                                      1
                                    )
                                  : _c(
                                      "el-select",
                                      {
                                        staticClass: "w-100",
                                        model: {
                                          value: _vm.bot.voice_model,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.bot,
                                              "voice_model",
                                              $$v
                                            )
                                          },
                                          expression: "bot.voice_model",
                                        },
                                      },
                                      _vm._l(
                                        _vm.voice_models,
                                        function (model) {
                                          return _c("el-option", {
                                            key: model.value,
                                            attrs: {
                                              label: model.name,
                                              value: model.value,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.bot.type == _vm.AloAi.TYPE_VOICE
                              ? _c("el-form-item", [
                                  _c("div", { staticClass: "form-label" }, [
                                    _c("h5", { staticClass: "text-dark" }, [
                                      _vm._v("Select voice"),
                                    ]),
                                    _vm._v(" "),
                                    _c("small", [
                                      _vm._v(
                                        " Choose a preset voice for this agent. "
                                      ),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex align-items-center width-300",
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticClass: "w-100",
                                          on: { change: _vm.stopAudio },
                                          model: {
                                            value: _vm.bot.voice,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.bot, "voice", $$v)
                                            },
                                            expression: "bot.voice",
                                          },
                                        },
                                        _vm._l(_vm.voices, function (voice) {
                                          return _c("el-option", {
                                            key: voice.value,
                                            attrs: {
                                              label: voice.name,
                                              value: voice.value,
                                            },
                                          })
                                        }),
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          staticClass: "ml-2",
                                          attrs: { circle: "" },
                                          on: { click: _vm.toggleAudio },
                                        },
                                        [
                                          _c("i", {
                                            class: _vm.isPlaying
                                              ? "fas fa-stop"
                                              : "fas fa-play",
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("hr"),
                            _vm._v(" "),
                            _c("el-form-item", [
                              _c("div", { staticClass: "form-label" }, [
                                _c("h5", { staticClass: "text-dark" }, [
                                  _vm._v("Engagement expiration"),
                                ]),
                                _vm._v(" "),
                                _c("small", [
                                  _vm._v(
                                    "\n                    How long will the agent's engagement with a contact last\n                    before it expires?\n                  "
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "row align-items-center" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "col-6" },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticClass: "mb-0",
                                          attrs: { prop: "ttl" },
                                        },
                                        [
                                          _c("el-input-number", {
                                            staticClass: "w-100",
                                            attrs: {
                                              min: 1,
                                              max: 14,
                                              precision: 0,
                                              controls: true,
                                            },
                                            on: { change: _vm.updateBotTTL },
                                            model: {
                                              value: _vm.bot_ttl,
                                              callback: function ($$v) {
                                                _vm.bot_ttl = $$v
                                              },
                                              expression: "bot_ttl",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col-6" }, [
                                    _vm._v("Days"),
                                  ]),
                                ]
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-tab-pane",
                  {
                    attrs: {
                      label: "Instructions",
                      name: "instructions",
                      "data-tab-error": "instructions",
                    },
                  },
                  [
                    _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                      _vm._v("Prompt"),
                      _c("span", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.tabLabelErrorIcon(_vm.tab_errors.instructions)
                          ),
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "row justify-content-center pt-3" },
                      [
                        _c(
                          "div",
                          { staticClass: "col-md-12 col-lg-10 main-col" },
                          [
                            _c("alo-ai-form-instructions", {
                              attrs: { bot: _vm.bot },
                              on: {
                                validate: function ($event) {
                                  return _vm.preValidateForm("agent_settings")
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.bot.type == _vm.AloAi.TYPE_TEXT
                  ? _c(
                      "el-tab-pane",
                      {
                        attrs: {
                          label: "Actions",
                          name: "actions",
                          "data-tab-error": "actions",
                        },
                      },
                      [
                        _c(
                          "span",
                          { attrs: { slot: "label" }, slot: "label" },
                          [
                            _vm._v("Actions"),
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.tabLabelErrorIcon(_vm.tab_errors.actions)
                                ),
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "row justify-content-center pt-3" },
                          [
                            _c(
                              "div",
                              { staticClass: "col-md-12 col-lg-10 main-col" },
                              [
                                _c("h5", { staticClass: "text-dark" }, [
                                  _c("i", { staticClass: "el-icon-setting" }),
                                  _vm._v(
                                    "\n                Tools\n              "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  [
                                    _c("div", { staticClass: "form-label" }, [
                                      _c("small", [
                                        _vm._v(
                                          " Select the functions to use with this bot. "
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "el-select",
                                      {
                                        staticClass: "w-100",
                                        attrs: {
                                          multiple: "",
                                          filterable: "",
                                          placeholder: "Select functions",
                                        },
                                        on: { change: _vm.changeFunction },
                                        model: {
                                          value: _vm.functions_list,
                                          callback: function ($$v) {
                                            _vm.functions_list = $$v
                                          },
                                          expression: "functions_list",
                                        },
                                      },
                                      _vm._l(
                                        _vm.toolsList,
                                        function (tool, index) {
                                          return _c("el-option", {
                                            key: index,
                                            attrs: {
                                              label: tool.name,
                                              value: tool.uuid,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "form-label d-flex" },
                                  [
                                    _c("el-switch", {
                                      staticClass: "mr-2 align-self-center",
                                      attrs: { "active-color": "#00BF50" },
                                      model: {
                                        value: _vm.bot.follow_up_enabled,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.bot,
                                            "follow_up_enabled",
                                            $$v
                                          )
                                        },
                                        expression: "bot.follow_up_enabled",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("h5", { staticClass: "mb-0 mt-1" }, [
                                      _vm._v("Follow Up"),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-form-item",
                                  [
                                    _c("div", { staticClass: "form-label" }, [
                                      _c("small", [
                                        _vm._v(
                                          "\n                    Set the frequency you want this agent to follow up the\n                    conversation when the contact is unresponsive.\n                  "
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.bot.follow_up_enabled,
                                            expression: "bot.follow_up_enabled",
                                          },
                                        ],
                                        staticClass: "row",
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "col-4" },
                                          [
                                            _c(
                                              "el-form-item",
                                              { staticClass: "no-border" },
                                              [
                                                _c(
                                                  "el-input",
                                                  {
                                                    staticClass: "w-100",
                                                    attrs: {
                                                      type: "number",
                                                      disabled:
                                                        !_vm.bot
                                                          .follow_up_enabled,
                                                      max: 100,
                                                      min: 1,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.bot
                                                          .follow_up_frequency,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.bot,
                                                          "follow_up_frequency",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "bot.follow_up_frequency",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "template",
                                                      { slot: "append" },
                                                      [
                                                        _vm._v("Attempt"),
                                                        _vm.bot
                                                          .follow_up_frequency !=
                                                        1
                                                          ? _c("span", [
                                                              _vm._v("s"),
                                                            ])
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.bot.follow_up_enabled,
                                            expression: "bot.follow_up_enabled",
                                          },
                                        ],
                                        staticClass: "form-label my-2",
                                      },
                                      [
                                        _c("small", [
                                          _vm._v(
                                            " Set a delay within each follow up attempt. "
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("DurationPicker", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.bot.follow_up_enabled,
                                          expression: "bot.follow_up_enabled",
                                        },
                                      ],
                                      attrs: {
                                        current_seconds:
                                          _vm.bot.follow_up_delay,
                                      },
                                      on: {
                                        update_seconds:
                                          _vm.onUpdateFollowUpDelay,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "el-tab-pane",
                  {
                    attrs: {
                      label: "Deployment",
                      name: "deployment",
                      "data-tab-error": "deployment",
                    },
                  },
                  [
                    _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                      _vm._v("Routing"),
                      _c("span", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.tabLabelErrorIcon(_vm.tab_errors.deployment)
                          ),
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "row justify-content-center pt-3" },
                      [
                        _c(
                          "div",
                          { staticClass: "col-md-12 col-lg-10 main-col" },
                          [
                            _vm.bot.type == _vm.AloAi.TYPE_TEXT
                              ? _c(
                                  "el-form-item",
                                  { attrs: { prop: "campaign_ids" } },
                                  [
                                    _c("div", { staticClass: "form-label" }, [
                                      _c("h5", { staticClass: "text-dark" }, [
                                        _vm._v("Lines"),
                                      ]),
                                      _vm._v(" "),
                                      _c("small", [
                                        _vm._v(
                                          "\n                    Select one or multiple lines to connect to this agent.\n                  "
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "el-select",
                                      {
                                        staticClass: "w-100",
                                        attrs: {
                                          multiple: "",
                                          filterable: "",
                                          clearable: "",
                                          placeholder: "Select lines",
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.preValidateForm(
                                              "agent_settings"
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.selected_campaigns,
                                          callback: function ($$v) {
                                            _vm.selected_campaigns = $$v
                                          },
                                          expression: "selected_campaigns",
                                        },
                                      },
                                      _vm._l(_vm.lines, function (line) {
                                        return _c("el-option", {
                                          key: line.id,
                                          attrs: {
                                            label: line.name,
                                            value: line.id,
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _c(
                                  "el-alert",
                                  {
                                    staticClass: "my-2",
                                    attrs: {
                                      type: "info",
                                      title: "Lines",
                                      closable: false,
                                      "show-icon": "",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                Go to Lines > Settings to route your inbound or missed calls\n                to the agent to start using it.\n              "
                                    ),
                                  ]
                                ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }