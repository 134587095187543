var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-body", attrs: { id: "view" } },
    [
      _vm.shouldShowAloAi
        ? _c(
            "div",
            { staticClass: "container-listing" },
            [
              _c("div", { staticClass: "container-header d-flex" }, [
                _c("h3", { staticClass: "listing-heading" }, [
                  _vm._v("AloAi Agents"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "ml-auto" }, [
                  _c(
                    "div",
                    { staticClass: "d-flex" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "medium", round: "" },
                          on: { click: _vm.openCreateAgentDialog },
                        },
                        [
                          _c("i", { staticClass: "fa fa-plus mr-1" }),
                          _vm._v(" "),
                          _c("strong", [_vm._v("New Agent")]),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-dialog",
                {
                  attrs: {
                    title: "Choose your AloAi Agent type:",
                    visible: _vm.createAgentDialogVisible,
                    top: "10vh",
                    width: "45%",
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.createAgentDialogVisible = $event
                    },
                  },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: { change: _vm.goToSelectDirection },
                      model: {
                        value: _vm.agent_type,
                        callback: function ($$v) {
                          _vm.agent_type = $$v
                        },
                        expression: "agent_type",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "row row-filter aloai-agents" },
                        [
                          _c(
                            "div",
                            { staticClass: "col-md-6" },
                            [
                              _c(
                                "el-card",
                                {
                                  staticClass: "cursor-pointer",
                                  attrs: { shadow: "hover" },
                                },
                                [
                                  _c(
                                    "el-radio",
                                    {
                                      staticClass: "w-100",
                                      attrs: { label: "text" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex align-items-center mb-2",
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "text-2x mr-2 material-icons text-indigo-500",
                                            },
                                            [_vm._v("sms")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "text-lg font-weight-bold text-grey-9",
                                            },
                                            [_vm._v("Text")]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("p", { staticClass: "text-md l-h" }, [
                                        _vm._v(
                                          "This agent can be used to handle marketing campaigns, sales & support over text."
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-md-6" },
                            [
                              _c(
                                "el-card",
                                {
                                  staticClass: "cursor-pointer",
                                  attrs: {
                                    shadow:
                                      _vm.isCompanyPartOfAlowareDemoCompanies()
                                        ? "hover"
                                        : "never",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio",
                                    {
                                      staticClass: "w-100",
                                      attrs: {
                                        label: "voice",
                                        disabled:
                                          !_vm.isCompanyPartOfAlowareDemoCompanies(),
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex align-items-center mb-2",
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "text-2x mr-2 material-icons text-indigo-500",
                                            },
                                            [_vm._v("phone_in_talk")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "text-lg font-weight-bold text-grey-9",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  !_vm.isCompanyPartOfAlowareDemoCompanies()
                                                    ? "Voice (Coming Soon)"
                                                    : "Voice"
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("p", { staticClass: "text-md l-h" }, [
                                        _vm._v(
                                          "This agent can be used to handle inbound and missed calls for different use cases."
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-dialog",
                {
                  attrs: {
                    title: "Choose your AloAi Agent direction:",
                    visible: _vm.selectAgentDirectionVisible,
                    top: "10vh",
                    width: "45%",
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.selectAgentDirectionVisible = $event
                    },
                  },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: { change: _vm.createAgent },
                      model: {
                        value: _vm.agent_direction,
                        callback: function ($$v) {
                          _vm.agent_direction = $$v
                        },
                        expression: "agent_direction",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "row row-filter aloai-agents" },
                        [
                          _c(
                            "div",
                            { staticClass: "col-md-6" },
                            [
                              _c(
                                "el-card",
                                {
                                  staticClass: "cursor-pointer",
                                  attrs: { shadow: "hover" },
                                },
                                [
                                  _c(
                                    "el-radio",
                                    {
                                      staticClass: "w-100",
                                      attrs: { label: "inbound" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex align-items-center mb-2",
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "text-2x mr-2 material-icons text-indigo-500",
                                            },
                                            [_vm._v("call_received")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "text-lg font-weight-bold text-grey-9",
                                            },
                                            [_vm._v("Inbound")]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("p", { staticClass: "text-md l-h" }, [
                                        _vm._v(
                                          "This agent can be used for Q&A, Support and other use cases."
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-md-6" },
                            [
                              _c(
                                "el-card",
                                {
                                  staticClass: "cursor-pointer",
                                  attrs: { shadow: "hover" },
                                },
                                [
                                  _c(
                                    "el-radio",
                                    {
                                      staticClass: "w-100",
                                      attrs: { label: "outbound" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex align-items-center mb-2",
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "text-2x mr-2 material-icons text-indigo-500",
                                            },
                                            [_vm._v("call_made")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "text-lg font-weight-bold text-grey-9",
                                            },
                                            [_vm._v("Outbound")]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("p", { staticClass: "text-md l-h" }, [
                                        _vm._v(
                                          "This agent can be used for initial reach-out for your contacts."
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("alo-ai-table", {
                attrs: { bots: _vm.bots },
                on: {
                  "bot-update": _vm.onBotUpdate,
                  "bot-delete": _vm.onBotDelete,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.shouldShowAloAi && _vm.shouldShowUpgradeNow
        ? _c("upgrade-now-page", {
            staticClass: "mt-5",
            attrs: {
              "image-link": "/assets/images/AloAi.svg",
              text: "The leading conversational AI supercharging your path to explosive sales growth",
              "extra-text":
                "Enhance your team’s efficiency and lower operational expenses by automating conversations throughout the entire customer journey. Our AI agent replaces most BDRs and SDRs and will escalate automatically to humans.",
              "title-text": "AloAi Agents",
              "kb-link": "https://aloware.com/ai-bot/",
              "custom-link":
                "https://share.hsforms.com/1RLbs7DggS1i2Lhwld-nwlQ2qfyk",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }