<template>
  <div>
    <div class="b-b nav-active-greenish">
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a class="border border-right-0 rounded-top-left"
             :class="navClass.read"
             href=""
             data-toggle="tab"
             :data-target="`#${type}_tts`"
             aria-expanded="true">
            <i class="fa fa-robot"></i>
            Text to Speech
          </a>
        </li>
        <li class="nav-item"
            v-if="allowUploadFile">
          <a class="border rounded-top-right"
             :class="navClass.play"
             href=""
             data-toggle="tab"
             :data-target="`#${type}_file`"
             aria-expanded="false">
            <i class="fa fa-play text-md"></i>
            Play Recording
          </a>
        </li>
        <li class="nav-item"
            v-if="file">
          <a class="nav-link"
             @click.prevent="deleteFile()">
          </a>
        </li>
      </ul>
    </div>
    <div class="tab-content p-a m-b-md border border-top-0">
      <div :class="containerClass.read"
           :id="`${type}_tts`"
           aria-expanded="true">
        <el-form-item class="mb-4"
                      :label="label"
                      :prop="ttsFormItem">
          <div class="row">
            <div class="col-12">
              <div class="input-group">
                <input type="text"
                       class="form-control pt-2 pb-2"
                       :value="tts"
                       @input="emitUpdateEvent"/>
                <variable-dialog></variable-dialog>
              </div>
            </div>
          </div>
        </el-form-item>
      </div>
      <div :class="containerClass.play"
           :id="`${type}_file`"
           aria-expanded="false"
           v-if="allowUploadFile">
        <audio-recorder
            v-if="file === null"
            class="audio-recorder-wrapper"
            :action="actionUrl"
            :upload_type="uploadType"
            @applyUploadedAudio="applyUploadedAudio">
        </audio-recorder>
        <div class="audio-uploader-wrapper">
          <el-upload
              v-if="file === null"
              drag
              :action="actionUrl"
              :headers="headers"
              :on-success="onSuccess"
              :on-error="onFailed"
              :on-progress="progressUpload"
              :before-upload="beforeUpload"
              :file-list="uploadFileList">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              Drop file here or <em>click to
              upload</em>
            </div>
            <div class="el-upload__tip"
                 slot="tip">
              MP3/WAV file (less than 8MB)
            </div>
          </el-upload>
          <el-progress :text-inside="true"
                       :stroke-width="18"
                       :percentage="uploadPercentage"
                       :status="uploadStatus"
                       v-if="file === null">
          </el-progress>
          <span v-if="file !== null">
            <p class="_600">{{ label }}</p>
            <audio controls>
              <source :src="'/static/uploaded_file/' + file">
              Your browser does not support the audio element.
            </audio>
          </span>
          <button class="btn btn-sm btn-danger pull-right"
                  v-if="file !== null && allowDeleteFile"
                  @click.prevent="deleteFile()">
            <i class="material-icons loader pull-left"
               v-show="loading">&#xE863;</i>
            <i class="material-icons pull-left"
               v-show="!loading">&#xE872;</i>
            <span>Remove file</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VariableDialog from "./variable-dialog.vue";
import {acl_mixin, form_validation_mixin} from "../mixins";

export default {
  name: "text-audio-input",

  mixins: [
    acl_mixin,
    form_validation_mixin
  ],

  components: { VariableDialog },

  props: {
    allowDeleteFile: {
      required: true
    },

    allowUploadFile: {
      required: true
    },

    audioFileMessages: {
      type: Object,
      required: true
    },

    campaign: {
      required: true
    },

    file: {
      default: null,
      required: false
    },

    form: {
      required: true
    },

    headers: {
      default: null,
      required: false
    },

    ivr: {
      required: true
    },

    label: {
      default: 'Message'
    },

    tts: {
      default: '',
      required: false
    },

    ttsFormItem: {
      required: true
    },

    type: {
      default: null,
      required: true
    },

    uploadType: {
       required: true
    }
  },

  data() {
    return {
      loading: false,
      uploadPercentage: 0,
      uploadFileList: [],
      uploadStatus: 'success',
      ttsForm: this.tts
    }
  },

  computed: {
    navClass (){
      return {
        read: {
          'disabled': this.file !== null,
          'nav-link': true,
          'active': this.file === null
        },
        play: {
          'nav-link': true,
          'active': this.file !== null
        }
      }
    },

    containerClass () {
      return {
        read: {
          'tab-pane': true,
          'animated': true,
          'fadeIn': true,
          'text-muted': true,
          'active': this.file === null
        },
        play: {
          'tab-pane': true,
          'animated': true,
          'fadeIn': true,
          'text-muted': true,
          'active': this.file !== null
        },
      }
    },

    actionUrl () {
      return '/api/v1/campaign/' + this.campaign.id + '/upload-ivr/' + this.type
    },
  },

  methods: {
    emitUpdateEvent (value) {
      this.$emit('update:tts', value.target.value)
      this.$emit('input', value.target.value)
    },

    deleteFile () {
      const params = {}
      if (this.ivr.id) {
        params.ivr_id = this.ivr.id
      }

      this.loading = true

      axios.delete('/api/v1/campaign/' + this.campaign.id + '/delete/' + this.type, {params})
          .then(res => {
            let message = ''
            this.uploadPercentage = 0
            this.uploadStatus = 'success'
            this.uploadFileList = []
            this.loading = false
            this.$emit('deleteFile')

            switch (this.type) {
              case 'csat_preambles':
                message = 'CSAT preamble file deleted successfully'
                break
              case 'csat_prompts':
                message = 'CSAT prompt file deleted successfully'
                break
              case 'csat_outros':
                message = 'CSAT outro file deleted successfully'
                break
            }
            this.$notify({
              offset: 95,
              title: 'Line',
              message: message,
              type: 'success',
              showClose: true,
            })
          }).catch(err => {
            this.loading = false
            this.uploadPercentage = 0
            this.$root.handleErrors(err.response)
          })
    },

    onSuccess (res) {
      this.$notify({
        offset: 95,
        title: 'Line',
        message: this.audioFileMessages[this.uploadType],
        type: 'success',
        showClose: true,
      })

      this.uploadStatus = 'success'
      this.uploadPercentage = 0
      this.$emit('success', res.file_name)
    },

    onFailed (err) {
      this.handleUploadErrors(err.message)
      this.uploadStatus = 'exception'
      this.uploadPercentage = 0
      this.preValidateForm(this.form)
    },

    beforeUpload () {
      this.uploadStatus = 'success'
      this.uploadPercentage = 0
    },

    progressUpload (event) {
      this.uploadPercentage = parseInt(event.percent)
    },

    applyUploadedAudio (data) {
      this.$emit('applyUploadedAudio', data)
    },

    handleUploadErrors (error) {
      if (typeof error === 'string') {
        error = JSON.parse(error)
      }
      let err
      if (error.message === 'This action is unauthorized.') {
        err = {
          status: 403,
        }
      } else {
        err = {
          status: 422,
          data: {
            errors: error.errors.file
          }
        }
      }

      this.$root.handleErrors(err)
    }
  }
}
</script>
