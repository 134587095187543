// Types
export const TYPE_TEXT = 1
export const TYPE_VOICE = 2

// Directions
export const DIRECTION_INBOUND = 1
export const DIRECTION_OUTBOUND = 2

// Models
export const MODEL_GPT_35_T = 1
export const MODEL_GPT_35_T_1106 = 2
export const MODEL_GPT_4_1106_PREVIEW = 3
export const MODEL_GPT_4 = 4
export const MODEL_GPT_4_T = 5
export const MODEL_GPT_4O = 6
export const MODEL_GPT_4O_MINI = 7

// Voice Models
export const VOICE_MODEL_GPT_4O_REALTIME_PREVIEW = 1
export const VOICE_MODEL_GPT_4O_MINI_REALTIME_PREVIEW = 2

// Voices
export const VOICE_ALLOY = 1
export const VOICE_ASH = 2
export const VOICE_BALLAD = 3
export const VOICE_CORAL = 4
export const VOICE_ECHO = 5
export const VOICE_SAGE = 6
export const VOICE_SHIMMER = 7
export const VOICE_VERSE = 8

// Follow-Up Units
export const UNIT_MINUTES = 1
export const UNIT_HOURS = 2
export const UNIT_DAYS = 3
