<template>
    <div class="wizard">
        <div class="wizard-header">
            <h2 v-if="success">Congratulations!</h2>
            <h2 v-else>Add Phone Number</h2>
        </div>
        <div class="dropdown-divider"></div>
        <div>
            <el-form ref="country_code"
                     label-position="top"
                     class="mb-4"
                     :model="incoming_number"
                     :rules="rules_country"
                     :inline="true">
                <el-form-item class="w-100"
                              prop="country_code"
                              label="Country">
                    <country-selector :show_prefix="true"
                                      :supported_country_codes="supported_country_codes"
                                      :initial_country_code="initial_country_code"
                                      :force_initial_country_code="true"
                                      :show_tips="true"
                                      :disabled="loading"
                                      @changed="changeCountry">
                    </country-selector>
                </el-form-item>
            </el-form>
            <div v-if="incoming_number.country_code !== null">
                <div class="row">
                    <div class="col-12">
                        <el-form v-if="incoming_number.country_code !== null"
                                 ref="campaign_number_search"
                                 label-position="top"
                                 :inline="true"
                                 @submit.prevent.native="changeAreaCode">
                            <el-form-item class="d-inline-block width-300 fixed">
                                <label class="el-form-item__label d-inline-block col-12">Number</label>
                                <el-input type="text"
                                          pattern="\d*"
                                          :maxlength="area_code_max_length"
                                          v-model="number_search"
                                          clearable
                                          @keyup.enter.native="changeAreaCode">
                                </el-input>
                            </el-form-item>
                            <el-form-item class="col-3 d-inline-block px-0">
                                <label class="el-form-item__label d-inline-block col-12">Match To</label>
                                <el-select v-model="match_to"
                                           default-first-option
                                           filterable
                                           autocomplete="off"
                                           @change="changeMatchTo">
                                    <el-option
                                        value="area code"
                                        label="Area Code"
                                        :disabled="disableAreaCode">
                                    </el-option>
                                    <el-option value="left"
                                               label="First part of number">
                                        First part of number
                                    </el-option>
                                    <el-option value="any"
                                               label="Anywhere in number">
                                        Anywhere in number
                                    </el-option>
                                    <el-option value="right"
                                               label="Last part of number">
                                        Last part of number
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item class="col-2 d-inline-block pl-0 pr-2 align-bottom">
                                <el-button type="primary"
                                           @click.prevent="changeAreaCode"
                                           :disabled="loading">
                                    <i class="fa fa-search"></i>
                                    Search
                                </el-button>
                            </el-form-item>
                        </el-form>
                        <el-form label-position="left">
                            <el-form-item class="mb-0 mt-3">
                                <label class="el-form-item__label col-12 px-0">Type</label>
                            </el-form-item>
                        </el-form>
                        <el-radio-group v-model="incoming_number.phone_number_type"
                                        size="small"
                                        class="no-select mb-2"
                                        v-if="['US', 'CA'].includes(incoming_number.country_code)"
                                        @change="changePhoneNumberType">
                                <el-tooltip class="item"
                                            effect="dark"
                                            :content="local_tooltip"
                                            placement="top"
                                            :disabled="!disableLocalNumbers">
                                    <el-radio-button :label="IncomingNumberTypes.LOCAL_PHONE_NUMBERS" :disabled="disableLocalNumbers">
                                        Local
                                    </el-radio-button>
                                </el-tooltip>
                                <el-tooltip class="item"
                                            effect="dark"
                                            content="Can’t add a toll-free number to a line with one or more local numbers"
                                            placement="top"
                                            :disabled="!disableTollFreeNumbers">
                                    <el-radio-button :label="IncomingNumberTypes.TOLL_FREE_NUMBERS" :disabled="disableTollFreeNumbers">
                                        Toll-free
                                    </el-radio-button>
                                </el-tooltip>
                            </el-radio-group>
                            <el-radio-group v-model="incoming_number.phone_number_type"
                                            size="small"
                                            class="no-select mb-2"
                                            v-else
                                            @change="changePhoneNumberType">
                                <el-tooltip class="item"
                                            effect="dark"
                                            :content="local_tooltip"
                                            placement="top"
                                            :disabled="!disableLocalNumbers">
                                    <el-radio-button :label="IncomingNumberTypes.LOCAL_PHONE_NUMBERS" :disabled="disableLocalNumbers">
                                        Local
                                    </el-radio-button>
                                </el-tooltip>
                                <el-tooltip class="item"
                                            effect="dark"
                                            :content="mobile_tooltip"
                                            placement="top"
                                            :disabled="!disableMobileNumbers">
                                    <el-radio-button :label="IncomingNumberTypes.MOBILE_PHONE_NUMBERS" :disabled="disableMobileNumbers">
                                        Mobile
                                    </el-radio-button>
                                </el-tooltip>
                            </el-radio-group>
                        <el-form ref="campaign_incoming_number"
                                 label-position="top"
                                 label-width="300px"
                                 :rules="rules_phone_number"
                                 :model="incoming_number"
                                 v-loading="loading">
                            <el-form-item prop="phone_number">
                                <div v-if="[IncomingNumberTypes.LOCAL_PHONE_NUMBERS, IncomingNumberTypes.MOBILE_PHONE_NUMBERS].includes(incoming_number.phone_number_type)">
                                    <div v-if="!changed">
                                        <label class="el-form-item__label">
                                            Local Phone Numbers <span v-if="local_phone_number !== null">to {{ local_phone_number }}</span>
                                            ({{
                                            auth.user.profile.rate.local_pn
                                            | fixRounding(2) | toCurrency }}/mo surcharge)
                                        </label>
                                    </div>
                                    <div v-else>
                                        <label class="el-form-item__label">Phone Numbers in the {{ number_search
                                            }} area code</label>
                                    </div>
                                    <div v-if="available_numbers">
                                        <div class="el-form-item__content">
                                            <el-radio-group v-model="incoming_number.phone_number"
                                                            class="w-full"
                                                            @change="changeNumber">
                                                <div class="row"
                                                     v-for="i in Math.ceil(available_numbers.length / 4)">
                                                    <div class="col-md-3 col-6"
                                                         v-for="item in available_numbers.slice((i - 1) * 4, i * 4)">
                                                        <el-radio :label="item.sid">
                                                            {{ item.phone_number | fixPhone }}
                                                        </el-radio>
                                                    </div>
                                                </div>
                                                <div class="row" v-if="available_numbers.length === 0">
                                                    <div class="col-12">
                                                            <span class="b-l pl-2 text-sm text-muted">
                                                                No numbers available
                                                            </span>
                                                    </div>
                                                </div>
                                            </el-radio-group>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="incoming_number.phone_number_type === IncomingNumberTypes.TOLL_FREE_NUMBERS">
                                    <label class="el-form-item__label">Toll-Free Numbers ({{
                                        auth.user.profile.rate.tollfree_pn
                                        | fixRounding(2) | toCurrency }}/mo surcharge)</label>
                                    <div class="el-form-item__content">
                                        <el-radio-group v-model="incoming_number.phone_number"
                                                        class="w-full"
                                                        @change="changeNumber">
                                            <div class="row"
                                                 v-for="i in Math.ceil(available_tollfree_numbers.length / 4)">
                                                <div class="col-md-3 col-6"
                                                     v-for="item in available_tollfree_numbers.slice((i - 1) * 4, i * 4)">
                                                    <el-radio :label="item.sid">
                                                        {{ item.phone_number | fixPhone }}
                                                    </el-radio>
                                                </div>
                                            </div>
                                            <div class="row" v-if="available_tollfree_numbers.length === 0">
                                                <div class="col-12">
                                                        <span class="b-l pl-2 text-sm text-muted">
                                                            No numbers available
                                                        </span>
                                                </div>
                                            </div>
                                        </el-radio-group>
                                    </div>
                                </div>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-7 text-left">
                    <p v-if="isLocalTypeSelected && lineHasMessagingService && is10DlcNumber">
                        <span class="font-weight-bold">Note:</span> This phone number will be automatically added to the line's A2P Campaign <span class="font-weight-bold">#{{ lineMessagingService.id }} {{ messagingServiceFormattedUseCase(lineMessagingService?.a2p_use_case?.use_case) }}</span>
                    </p>
                </div>
                <div class="col-5 text-right">
                    <el-button type="success"
                               @click="submitForm('incoming_number')"
                               :disabled="loading_btn || !validated">
                        <i class="material-icons loader"
                           v-show="loading_btn">&#xE863;</i>
                        <i class="fa fa-plus"
                           v-show="!loading_btn"></i>
                        Add Number
                    </el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import auth from '../auth'
import {
    acl_mixin,
    campaign_mixin,
    form_validation_mixin,
    phone_wizard_mixin,
    teams_mixin
} from '../mixins'
import {mapState} from 'vuex'
import * as IncomingNumberTypes from '../constants/incoming-number-types'

export default {
        mixins: [
            acl_mixin,
            campaign_mixin,
            form_validation_mixin,
            phone_wizard_mixin,
            teams_mixin
        ],

        props: {
            campaign: {
                required: true
            }
        },

        data() {
            return {
                auth: auth,
                incoming_number: {
                    country_code: null,
                    phone_number_type: IncomingNumberTypes.LOCAL_PHONE_NUMBERS,
                    phone_number: null
                },
                number_search: null,
                match_to: 'area code',
                available_numbers: [],
                available_tollfree_numbers: [],
                loading: false,
                showTooltip: false,
                rules_country: {
                    country_code: [
                        {
                            required: true,
                            message: 'Please select a country',
                            trigger: 'blur'
                        }
                    ]
                },
                rules_phone_number: {
                    phone_number: [
                        {
                            required: true,
                            message: 'Please select a phone number to proceed',
                            trigger: 'blur'
                        }
                    ]
                },
                changed: false,
                first_user: null,
                initial_country_code: null,
                supported_country_codes: ['US', 'PR', 'CA', 'GB', 'AU'],
                area_code_max_length: 3,
                local_phone_number: null,
                loading_btn: false,
                success: false,
                IncomingNumberTypes,
            }
        },

        computed: {
            ...mapState({
                users: state => state.users,
                ring_groups: state => state.ring_groups,
                current_company: state => state.cache.current_company
            }),

            firstUser() {
                return this.getCampaignFirstUserPhoneNumber(this.campaign, this.ring_groups, this.users, this.all_teams, true)
            },

            disableAreaCode() {
                if (!['US', 'CA'].includes(this.incoming_number.country_code)) {
                    this.match_to = this.match_to === 'area code' ? 'left' : this.match_to

                    return true
                }

                return false
            },

            disableLocalNumbers() {
                let has_non_local_numbers = this.campaign.incoming_numbers.filter(incoming_number => incoming_number.is_tollfree).length > 0

                // Disable if we can't buy UK Local Numbers
                if (this.incoming_number.country_code === 'GB' && !this.canBuyUkLocalNumbers) {
                    this.local_tooltip = 'An Approved UK Regulatory Bundle & Address is required to purchase UK Local Numbers.'
                    return true
                } else if (this.incoming_number.country_code === 'GB' && has_non_local_numbers) {
                    this.local_tooltip = 'Can’t add a Local Number to a line with one or more Mobile Numbers.'
                    return true
                }

                // Disable if we have any Toll-Free numbers
                if (has_non_local_numbers) {
                    this.local_tooltip = 'Can’t add a Local Number to a line with one or more Toll-Free Numbers'
                    return true
                }

                return false
            },

            disableTollFreeNumbers() {
                // Disable if we have any Local Numbers
                return this.campaign.incoming_numbers.filter(incoming_number => !incoming_number.is_tollfree).length > 0
            },

            disableMobileNumbers() {
                let has_non_mobile_numbers = this.campaign.incoming_numbers.filter(incoming_number => !incoming_number.is_tollfree).length > 0

                // Disable if we have any non Mobile Numbers
                if (has_non_mobile_numbers) {
                    this.mobile_tooltip = 'Can’t add a Mobile Number to a line with one or more Local Numbers'
                    return true
                }

                // Disable if we can't buy UK Mobile Numbers
                if (this.incoming_number.country_code === 'GB' && !this.canBuyUkMobileNumbers) {
                    this.mobile_tooltip = 'A UK Regulatory Bundle & Address are required to purchase UK Mobile Numbers.'
                    return true
                }

                return false
            },

            isLocalTypeSelected() {
                return [this.IncomingNumberTypes.LOCAL_PHONE_NUMBERS, this.IncomingNumberTypes.MOBILE_PHONE_NUMBERS].includes(this.incoming_number.phone_number_type)
            },

            is10DlcNumber() {
                return this.isLocalTypeSelected && ['US', 'CA'].includes(this.incoming_number.country_code)
            },

            isTollFreeTypeSelected() {
                return this.incoming_number.phone_number_type === this.IncomingNumberTypes.TOLL_FREE_NUMBERS
            },
        },

        created() {
            this.local_phone_number = null
            this.number_search = ''
            this.first_user = this.firstUser

            // if we find the user
            if (this.first_user) {
                this.local_phone_number = this.first_user.phone_number
            }

            if (this.auth.user && this.auth.user.profile && this.auth.user.profile.company) {
                this.incoming_number.country_code = this.auth.user.profile.company.country
            }

            // set initial phone number type
            // if we have any incoming number then we need to
            // initialize the type to set the default value and prevent the user from changing it
            if (this.disableLocalNumbers && ['US', 'CA'].includes(this.incoming_number.country_code)) {
                this.incoming_number.phone_number_type = this.IncomingNumberTypes.IncomingNumberTypes.TOLL_FREE_NUMBERS
            }

            this.searchIncomingNumber(12)
        },

        methods: {
            searchIncomingNumber: function (limit) {
                this.available_numbers = []
                this.available_tollfree_numbers = []
                this.incoming_number.phone_number = null
                this.validated = false

                let prevent_search = false
                // Prevent Search if we can't buy UK Local Numbers & fallbacked to Local Phone Numbers.
                if (this.incoming_number.country_code === 'GB') {
                    if (this.incoming_number.phone_number_type === this.IncomingNumberTypes.LOCAL_PHONE_NUMBERS && this.disableLocalNumbers) {
                        prevent_search = true
                    }

                    if (this.incoming_number.phone_number_type === this.IncomingNumberTypes.MOBILE_PHONE_NUMBERS && this.disableMobileNumbers) {
                        prevent_search = true
                    }
                }

                if (prevent_search) {
                    return
                }

                this.loading = true
                if (this.isLocalTypeSelected) {
                    axios.get('/api/v1/available-numbers', {
                        params: {
                            is_mobile: (this.incoming_number.phone_number_type === this.IncomingNumberTypes.MOBILE_PHONE_NUMBERS),
                            area_code: this.number_search,
                            match_to: this.match_to,
                            country_code: this.incoming_number.country_code,
                            campaign_id: this.campaign.id,
                            limit: limit
                        }
                    }).then(res => {
                        this.available_numbers = res.data
                        this.loading = false
                    }).catch(err => {
                        if (err.response.data.message && err.response.data.message.includes('Local.json was not found')) {
                            this.available_tollfree_numbers = []
                        } else {
                            this.$root.handleErrors(err.response)
                        }
                        this.loading = false
                    })
                }

                if (this.isTollFreeTypeSelected) {
                    axios.get('/api/v1/available-numbers', {
                        params: {
                            is_tollfree: true,
                            area_code: this.number_search,
                            match_to: this.match_to,
                            country_code: this.incoming_number.country_code,
                            limit: 8
                        }
                    }).then(res => {
                        this.available_tollfree_numbers = res.data
                        this.loading = false
                    }).catch(err => {
                        if (err.response.data.message && err.response.data.message.includes('TollFree.json was not found')) {
                            this.available_tollfree_numbers = []
                        } else {
                            this.$root.handleErrors(err.response)
                        }
                        this.loading = false
                    })
                }
            },

            getAreaCode(phoneNumber) {
                if (phoneNumber) {
                    return this.$options.filters.areaCode(phoneNumber)
                }

                return ''
            },

            preValidateForm(reset = false) {
                let form_names = ['country_code', 'campaign_incoming_number']
                if (reset === true) {
                    for (let form_index in form_names) {
                        let form_element = _.get(this.$refs, form_names[form_index], null)
                        if (form_element) {
                            form_element.clearValidate()
                        }
                    }
                    return
                }
                if (form_names.length > 0) {
                    for (let form_index in form_names) {
                        let form_element = _.get(this.$refs, form_names[form_index], null)
                        if (form_element) {
                            let fields = form_element.fields
                            if (fields.find((f) => f.validateState === 'validating')) {
                                setTimeout(() => {
                                    this.preValidateForm()
                                }, 100)
                            } else {
                                this.validated = this.validateForm(form_names[form_index]);
                            }
                        }
                    }
                }
            },

            changeAreaCode() {
                let phone_number = null
                if (this.first_user) {
                    phone_number = this.first_user.phone_number
                }
                this.changed = this.number_search !== this.getAreaCode(phone_number)
                this.preValidateForm()
                this.searchIncomingNumber(12)
            },

            changeNumber() {
                this.preValidateForm()
            },

            changeCountry(country_code) {
                this.preValidateForm()
                this.incoming_number.country_code = country_code
                this.incoming_number.phone_number_type = this.IncomingNumberTypes.LOCAL_PHONE_NUMBERS

                if (this.incoming_number.country_code === 'GB' && this.disableLocalNumbers) {
                    this.incoming_number.phone_number_type = this.IncomingNumberTypes.MOBILE_PHONE_NUMBERS
                }

                this.searchIncomingNumber(12)
            },

            changePhoneNumberType() {
                this.number_search = ''
                this.local_phone_number = null
                if (this.first_user) {
                    this.local_phone_number = this.first_user.phone_number
                }
                this.searchIncomingNumber(12)
            },

            changeMatchTo(match_to) {
                if (match_to === 'area code') {
                    this.area_code_max_length = 3
                    this.number_search = this.number_search.slice(0, 3)
                } else {
                    this.area_code_max_length = 10
                }
                this.preValidateForm()
            },

            submitForm (formName) {
                if (this.validateForm('country_code') && this.validateForm('campaign_incoming_number')) {
                    this.loading_btn = true
                    this.loading = true
                    axios.post('/api/v1/campaign/' + this.campaign.id + '/add-number', {
                        incoming_number: this.incoming_number.phone_number,
                        is_local: this.incoming_number.phone_number_type === this.IncomingNumberTypes.LOCAL_PHONE_NUMBERS,
                    }).then(res => {
                        this.$emit('add', res.data)
                        this.loading_btn = false
                        this.loading = false
                    })
                    .catch(err => {
                        this.$root.handleErrors(err.response)
                        this.loading_btn = false
                        this.loading = false
                    })
                }
            }
        }
    }
</script>
