<template>
    <div>
        <el-row :gutter="20">
            <el-col :span="8">
                <el-input
                    v-model.number="days"
                    type="number"
                    :min="0"
                    :max="30"
                    placeholder="Days"
                    @input="calculateSeconds"
                >
                    <template slot="append">Day<span v-if="days != 1">s</span></template>
                </el-input>
            </el-col>
            <el-col :span="8">
                <el-input
                    v-model.number="hours"
                    type="number"
                    :min="0"
                    :max="23"
                    placeholder="Hours"
                    @input="calculateSeconds"
                >
                    <template slot="append">Hour<span v-if="hours != 1">s</span></template>
                </el-input>
            </el-col>
            <el-col :span="8">
                <el-input
                    v-model.number="minutes"
                    type="number"
                    :min="0"
                    :max="59"
                    placeholder="Minutes"
                    @input="calculateSeconds"
                >
                    <template slot="append">Minute<span v-if="minutes != 1">s</span></template>
                </el-input>
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
    name: 'duration-picker',
    props: {
        current_seconds: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            days: 0,
            hours: 0,
            minutes: 0,
            total_seconds: 0,
        }
    },
    watch: {
        current_seconds: {
            immediate: true,
            handler(new_seconds) {
                this.convertToUnits(new_seconds)
            },
        },
    },
    methods: {
        calculateSeconds() {
            // Ensure that the values are not negative
            if (this.days < 0) {
                this.days = 0
            }

            // Parse to integer
            this.days = parseInt(this.days)

            if (this.hours < 0) {
                this.hours = 0
            }

            this.hours = parseInt(this.hours)

            if (this.minutes < 0) {
                this.minutes = 0
            }

            this.minutes = parseInt(this.minutes)

            this.total_seconds = this.days * 86400 + this.hours * 3600 + this.minutes * 60
            this.$emit('update_seconds', this.total_seconds)
        },
        convertToUnits(seconds) {
            this.days = Math.floor(seconds / 86400)
            seconds %= 86400
            this.hours = Math.floor(seconds / 3600)
            seconds %= 3600
            this.minutes = Math.floor(seconds / 60)

            this.total_seconds = this.days * 86400 + this.hours * 3600 + this.minutes * 60
        },
    },
}
</script>
