<template>
    <div class="_400">
        <div
            v-for="callIssue in filteredCallIssues"
        >
            <div class="border-top text-left px-4 py-3">
                <label class="form-control-label"> Agent: </label>
                {{ user?.full_name ?? '-' }}
                <div v-if="callIssue.event_name === RTT_EVENT">
                    <network-signal-strength
                        :value-issue="callIssue.avg"
                        :issue="networkIssues[callIssue.event_name]"
                        :title="networkIssues[callIssue.event_name].trigger"
                    />
                    <label class="form-control-label">Avg outbound Latency:</label>
                    {{ callIssue.avg }} ms
                    <br/>
                    <label class="form-control-label">Occurrences:</label>
                    {{ callIssue.count }} times
                    <br/><br/>

                    {{ networkIssues[callIssue.event_name].details }}
                </div>
                <div v-else-if="callIssue.event_name === MOS_EVENT">
                    <network-signal-strength
                        :value-issue="callIssue.avg"
                        :issue="networkIssues[callIssue.event_name]"
                        :title="networkIssues[callIssue.event_name].trigger"
                    />
                    <label class="form-control-label">Outbound MOS:</label>
                    {{ callIssue.avg }}
                    <br/>
                    <label class="form-control-label">Occurrences:</label>
                    {{ callIssue.count }} times
                    <br/><br/>

                    {{ networkIssues[callIssue.event_name].details }}
                </div>
                <div v-else-if="callIssue.event_name === JITTER_EVENT">
                    <network-signal-strength
                        :value-issue="callIssue.avg"
                        :issue="networkIssues[callIssue.event_name]"
                        :title="networkIssues[callIssue.event_name].trigger"
                    />
                    <label class="form-control-label">Avg inbound Jitter:</label>
                    {{ callIssue.avg }} ms
                    <br/>
                    <label class="form-control-label">Occurrences:</label>
                    {{ callIssue.count }} times
                    <br/><br/>

                    {{ networkIssues[callIssue.event_name].details }}
                </div>
                <div v-else-if="callIssue.event_name === BYTES_RECEIVED_EVENT">
                    <network-signal-strength
                        :value-issue="callIssue.avg"
                        :issue="networkIssues[callIssue.event_name]"
                        :title="networkIssues[callIssue.event_name].trigger"
                    />
                    <label class="form-control-label">Bytes received:</label>
                    {{ callIssue.avg }}
                    <br/>
                    <label class="form-control-label">Occurrences:</label>
                    {{ callIssue.count }} times
                    <br/><br/>

                    {{ networkIssues[callIssue.event_name].details }}
                </div>
                <div v-else-if="callIssue.event_name === BYTES_SENT_EVENT">
                    <network-signal-strength
                        :value-issue="callIssue.avg"
                        :issue="networkIssues[callIssue.event_name]"
                        :title="networkIssues[callIssue.event_name].trigger"
                    />
                    <label class="form-control-label">Bytes sent:</label>
                    {{ callIssue.avg }}
                    <br/>
                    <label class="form-control-label">Occurrences:</label>
                    {{ callIssue.count }} times
                    <br/><br/>

                    {{ networkIssues[callIssue.event_name].details }}
                </div>
                <div v-else-if="callIssue.event_name === HIGH_PACKET_LOSS_EVENT">
                    <network-signal-strength :value-issue="callIssue.avg"
                                             :issue="networkIssues[callIssue.event_name]"
                                             :title="networkIssues[callIssue.event_name].trigger"
                    />
                    <label class="form-control-label">Inbound Packet Loss:</label>
                    {{ callIssue.avg }}%
                    <br/>
                    <label class="form-control-label">Occurrences:</label>
                    {{ callIssue.count }} times
                    <br/><br/>

                    {{ networkIssues[callIssue.event_name].details }}
                </div>
                <div v-else-if="callIssue.event_name === HIGH_PACKETS_LOST_FRACTION_EVENT">
                    <network-signal-strength :value-issue="callIssue.avg"
                                             :issue="networkIssues[callIssue.event_name]"
                                             :title="networkIssues[callIssue.event_name].trigger"
                    />
                    <label class="form-control-label">Inbound Packet Loss Fraction:</label>
                    {{ callIssue.avg }}%
                    <br/>
                    <label class="form-control-label">Occurrences:</label>
                    {{ callIssue.count }} times
                    <br/><br/>

                    {{ networkIssues[callIssue.event_name].details }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import NetworkSignalStrength from './network-signal-strength'
import {
    networkIssues,
    RTT_EVENT,
    MOS_EVENT,
    JITTER_EVENT,
    BYTES_RECEIVED_EVENT,
    BYTES_SENT_EVENT,
    HIGH_PACKET_LOSS_EVENT,
    HIGH_PACKETS_LOST_FRACTION_EVENT,
} from '../../constants/network-logs/network-issues'

export default {
    name: 'network-logs-display',

    components: {NetworkSignalStrength},

    props: {
        callIssues: {
            required: true,
        },
        user:       {
            required: true,
        },
    },

    data() {
        return {
            networkIssues,
            RTT_EVENT,
            MOS_EVENT,
            JITTER_EVENT,
            BYTES_RECEIVED_EVENT,
            BYTES_SENT_EVENT,
            HIGH_PACKET_LOSS_EVENT,
            HIGH_PACKETS_LOST_FRACTION_EVENT
        }
    },

    computed: {
        filteredCallIssues() {
            return this.callIssues.filter((callIssue) => {
                return this.networkIssues[callIssue.event_name];
            });
        }
    },
}
</script>

<style scoped>
.form-control-label {
    padding-top: 0.2rem;
    padding-bottom: 0.5rem;
    margin-right: 4px;
}
</style>
