<template>
    <div class="app-body" id="view">
        <div v-if="shouldShowAloAi"
             class="container-listing">
            <div class="container-header d-flex">
                <h3 class="listing-heading">AloAi Agents</h3>
                <div class="ml-auto">
                    <div class="d-flex">
                        <el-button
                            type="primary"
                            size="medium"
                            round
                            @click="openCreateAgentDialog">
                            <i class="fa fa-plus mr-1"></i>
                            <strong>New Agent</strong>
                        </el-button>
                    </div>
                </div>
            </div>

            <!-- AloAi Agent type -->
            <el-dialog
                title="Choose your AloAi Agent type:"
                :visible.sync="createAgentDialogVisible"
                top="10vh"
                width="45%">
                <el-radio-group v-model="agent_type"
                                @change="goToSelectDirection">
                    <div class="row row-filter aloai-agents">
                        <div class="col-md-6">
                            <el-card shadow="hover"
                                     class="cursor-pointer">
                                <el-radio label="text"
                                          class="w-100">
                                    <div class="d-flex align-items-center mb-2">
                                        <i class="text-2x mr-2 material-icons text-indigo-500">sms</i>
                                        <span class="text-lg font-weight-bold text-grey-9">Text</span>
                                    </div>
                                    <p class="text-md l-h">This agent can be used to handle marketing campaigns, sales & support over text.</p>
                                </el-radio>
                            </el-card>
                        </div>
                        <div class="col-md-6">
                            <el-card :shadow="isCompanyPartOfAlowareDemoCompanies() ? 'hover' : 'never'"
                                     class="cursor-pointer">
                                <el-radio label="voice"
                                          class="w-100"
                                          :disabled="!isCompanyPartOfAlowareDemoCompanies()">
                                    <div class="d-flex align-items-center mb-2">
                                        <i class="text-2x mr-2 material-icons text-indigo-500">phone_in_talk</i>
                                        <span class="text-lg font-weight-bold text-grey-9">{{ !isCompanyPartOfAlowareDemoCompanies() ? 'Voice (Coming Soon)' : 'Voice' }}</span>
                                    </div>
                                    <p class="text-md l-h">This agent can be used to handle inbound and missed calls for different use cases.</p>
                                </el-radio>
                            </el-card>
                        </div>
                    </div>
                </el-radio-group>
            </el-dialog>

            <!-- AloAi Agent direction -->
            <el-dialog
                title="Choose your AloAi Agent direction:"
                :visible.sync="selectAgentDirectionVisible"
                top="10vh"
                width="45%">
                <el-radio-group v-model="agent_direction"
                                @change="createAgent">
                    <div class="row row-filter aloai-agents">
                        <div class="col-md-6">
                            <el-card shadow="hover"
                                     class="cursor-pointer">
                                <el-radio label="inbound"
                                          class="w-100">
                                    <div class="d-flex align-items-center mb-2">
                                        <i class="text-2x mr-2 material-icons text-indigo-500">call_received</i>
                                        <span class="text-lg font-weight-bold text-grey-9">Inbound</span>
                                    </div>
                                    <p class="text-md l-h">This agent can be used for Q&A, Support and other use cases.</p>
                                </el-radio>
                            </el-card>
                        </div>
                        <div class="col-md-6">
                            <el-card shadow="hover"
                                     class="cursor-pointer">
                                <el-radio label="outbound"
                                          class="w-100">
                                    <div class="d-flex align-items-center mb-2">
                                        <i class="text-2x mr-2 material-icons text-indigo-500">call_made</i>
                                        <span class="text-lg font-weight-bold text-grey-9">Outbound</span>
                                    </div>
                                    <p class="text-md l-h">This agent can be used for initial reach-out for your contacts.</p>
                                </el-radio>
                            </el-card>
                        </div>
                    </div>
                </el-radio-group>
            </el-dialog>

            <alo-ai-table
                :bots="bots"
                @bot-update="onBotUpdate"
                @bot-delete="onBotDelete"/>
        </div>
        <upgrade-now-page image-link="/assets/images/AloAi.svg"
                          text="The leading conversational AI supercharging your path to explosive sales growth"
                          extra-text="Enhance your team’s efficiency and lower operational expenses by automating conversations throughout the entire customer journey. Our AI agent replaces most BDRs and SDRs and will escalate automatically to humans."
                          title-text="AloAi Agents"
                          kb-link="https://aloware.com/ai-bot/"
                          custom-link="https://share.hsforms.com/1RLbs7DggS1i2Lhwld-nwlQ2qfyk"
                          class="mt-5"
                          v-if="!shouldShowAloAi && shouldShowUpgradeNow">
        </upgrade-now-page>
    </div>
</template>

<script>
import auth from '../../auth'
import AloAiTable from '../../components/aloai/aloai-table'
import {acl_mixin, html_mixin} from '../../mixins'
import {MessageBox} from 'element-ui'
import UpgradeNowPage from "../../components/upgrade-now-page"

export default {
    name: 'AloAiIndex',
    components: {
        UpgradeNowPage,
        AloAiTable,
    },
    mixins: [acl_mixin, html_mixin],
    data() {
        return {
            auth: auth,
            bots: [],
            agent_type: null,
            createAgentDialogVisible: false,
            agent_direction: null,
            selectAgentDirectionVisible: false,
        }
    },
    beforeRouteEnter(to, from, next) {
        // This logic help us wrap the Bots section with the Aloware App components.
        if (to.query.api_token) {
            return next({
                name: 'Login',
                query: {
                    api_token: to.query.api_token
                }
            })
        }

        auth.check().then((res) => {
            if (res.data.user.is_reseller) {
                // redirect to account management portal if the company is a reseller
                next({name: 'Account Management Portal'})
            } else {
                next()
            }
        }).catch((err) => {
            next({
                name: 'Login',
                query: {
                    redirect: to.fullPath
                }
            })
        })
    },
    methods: {
        onBotUpdate(bot) {
            this.bots = this.bots.map((c) => {
                if (c.id === bot.id) {
                    return bot
                }

                return c
            })
        },
        onBotDelete(botId) {
            MessageBox.confirm('Are you sure you want to delete this bot?', 'Warning', {
                confirmButtonText: 'Yes',
                cancelButtonText: 'Cancel',
                type: 'warning'
            }).then(async () => {
                axios.delete(`/api/v1/aloai/bot/${botId}`).then(res => {
                    this.bots = this.bots.filter((c) => {
                        return c.id !== botId
                    })

                    this.$message({
                        message: 'Bot deleted successfully',
                        type: 'success'
                    })
                }).catch(err => {
                    console.log(err)
                })
            }).catch(() => {
                // do nothing
            })
        },
        fetchBots() {
            axios.get('/api/v1/aloai/bot').then(res => {
                this.bots = res.data.data
            }).catch(err => {
                console.log(err)
            })
        },
        openCreateAgentDialog() {
            this.createAgentDialogVisible = true
        },
        goToSelectDirection() {
            if (this.agent_type === 'voice') {
                this.agent_direction = 'outbound'
                this.createAgent()
                return
            }
            this.createAgentDialogVisible = false
            this.selectAgentDirectionVisible = true
        },
        createAgent() {
            this.$router.push({name: 'AloAi: Create an agent', query: {type: this.agent_type, direction: this.agent_direction}})
        }
    },
    mounted() {
        this.setPageTitle('AloAi Agents')
        this.fetchBots()

        if (!this.shouldShowAloAiMenu) {
            this.goBack()
        }
    },
    watch: {
        $route({params, query}) {
            if (!this.shouldShowAloAiMenu) {
                this.goBack()
            }
        }
    }
}
</script>
