export const CALL_ROUTER_BEHAVIOR_MODE_FAST_FORWARD = 1
export const CALL_ROUTER_BEHAVIOR_MODE_ALOAI = 2
export const CALL_ROUTER_BEHAVIOR_MODE_DEAD_END = 3
export const CALL_ROUTER_BEHAVIOR_MODE_IVR = 4

/*
 * Fake call router behavior modes for UI only
 */
export const FAKE_CALL_ROUTER_BEHAVIOR_MODE_FAST_FORWARD_RING_GROUP = 1
export const FAKE_CALL_ROUTER_BEHAVIOR_MODE_FAST_FORWARD_USER = 2

export const FAKE_CALL_ROUTER_BEHAVIOR_MODE_DEAD_END = 3
