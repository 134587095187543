var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-12" },
          [
            _c("h5", { staticClass: "text-dark" }, [
              _c("i", { staticClass: "el-icon-top-right" }),
              _vm._v(" "),
              _vm.bot.type === _vm.AloAi.TYPE_TEXT &&
              _vm.bot.direction === _vm.AloAi.DIRECTION_OUTBOUND
                ? _c("span", [_vm._v("Opener Message")])
                : _c("span", [_vm._v("Greeting Message")]),
            ]),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { prop: "opener" } },
              [
                _c("div", { staticClass: "form-label" }, [
                  _vm.bot.type === _vm.AloAi.TYPE_TEXT
                    ? _c("small", [
                        _vm._v(
                          "\n                        The first message the agent sends to the contact. Use variables to personalize it.\n                    "
                        ),
                      ])
                    : _c("small", [
                        _vm._v(
                          "\n                        The first message the agent says to the contact. Use variables to personalize it.\n                    "
                        ),
                      ]),
                ]),
                _vm._v(" "),
                _c("el-input", {
                  attrs: { type: "textarea" },
                  on: {
                    input: function ($event) {
                      return _vm.preValidateForm("agent_settings")
                    },
                  },
                  model: {
                    value: _vm.bot.opener,
                    callback: function ($$v) {
                      _vm.$set(_vm.bot, "opener", $$v)
                    },
                    expression: "bot.opener",
                  },
                }),
                _vm._v(" "),
                _c(
                  "el-tooltip",
                  { attrs: { content: "Add variable", placement: "top" } },
                  [
                    _c("variables", {
                      on: { "variable-selected": _vm.variableSelected },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("hr", { staticClass: "mb-0 mt-1" }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-md-12" },
          [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { prop: "instructions" } },
              [
                _c("div", { staticClass: "form-label" }, [
                  _c("small", [
                    _vm._v(
                      "\n                        Write a personality for the agent and try to explain what you want the agent to perform in details.\n                    "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("el-input", {
                  ref: "textareaInput",
                  staticClass: "w-100 aloai-instructions",
                  attrs: {
                    type: "textarea",
                    rows: 14,
                    readonly: _vm.loadingTags,
                    autosize: { minRows: 14 },
                  },
                  on: { input: _vm.emitValidate },
                  model: {
                    value: _vm.bot.instructions[0].content,
                    callback: function ($$v) {
                      _vm.$set(_vm.bot.instructions[0], "content", $$v)
                    },
                    expression: "bot.instructions[0].content",
                  },
                }),
                _vm._v(" "),
                _vm.bot.type == _vm.AloAi.TYPE_TEXT
                  ? _c(
                      "div",
                      { staticClass: "d-flex align-items-center" },
                      [
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              content: "Add Disposition",
                              placement: "top",
                            },
                          },
                          [
                            _c("dispositions-dropdown", {
                              key: _vm.dispositionsDropdownKey,
                              attrs: {
                                "selected-disposition": _vm.currentDisposition,
                              },
                              on: {
                                "disposition-selected": _vm.dispositionSelected,
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-tooltip",
                          { attrs: { content: "Add Tags", placement: "top" } },
                          [
                            _c("tags-dropdown", {
                              key: _vm.tagsDropdownKey,
                              attrs: {
                                "custom-tags": _vm.currentTags,
                                "selected-tags-list": _vm.currentTagsIds,
                                disabled: _vm.loadingTags,
                              },
                              on: { "tags-selected": _vm.tagsSelected },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-tooltip",
                          { attrs: { content: "Add Lists", placement: "top" } },
                          [
                            _c("lists-dropdown", {
                              attrs: {
                                "custom-lists": _vm.currentLists,
                                "selected-list-ids": _vm.currentListsIds,
                              },
                              on: { "lists-selected": _vm.listsSelected },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              content: "Add Disengage Trigger",
                              placement: "top",
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "border-0 bg-transparent",
                                staticStyle: { "margin-left": "-2px" },
                                attrs: { size: "mini", circle: "" },
                                on: { click: _vm.addDisengageTrigger },
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-ellipsis-h",
                                  staticStyle: {
                                    padding: "3px",
                                    "border-radius": "50%",
                                    "font-size": "10px",
                                    color: "rgb(186, 186, 186)",
                                    border: "1px solid rgb(186, 186, 186)",
                                  },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("hr", { staticClass: "mb-0 mt-1" }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.bot.type === _vm.AloAi.TYPE_TEXT
        ? [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-md-12" },
                [
                  _vm._m(1),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticClass: "mb-0" },
                    [
                      _c("div", { staticClass: "form-label" }, [
                        _c("small", [
                          _vm._v(
                            "\n                            Upload the docs you want the bot to use as knowledge base. Supported formats are: docx, html, json, md, pdf, pptx, txt\n                        "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm.bot.is_assistant &&
                      _vm.bot.model !== _vm.AloAi.MODEL_GPT_35_T
                        ? _c("multi-upload", {
                            attrs: {
                              action: _vm.getAction,
                              "delete-action": _vm.deleteAction,
                              files: _vm.uploaded_files,
                            },
                            on: { change: _vm.uploadedFilesChanged },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.bot.is_assistant
                        ? _c("p", { staticClass: "text-md _400 text-danger" }, [
                            _vm._v(
                              "\n                        This is a bot, reach out to support to upgrade it to an assistant bot to use this feature.\n                    "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.bot.model === _vm.AloAi.MODEL_GPT_35_T
                        ? _c("p", { staticClass: "text-md _400 text-danger" }, [
                            _vm._v(
                              "\n                        You can't have KB files with this model.\n                    "
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h5", { staticClass: "text-dark" }, [
      _c("i", { staticClass: "el-icon-document" }),
      _vm._v("\n                Instructions\n            "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h5", { staticClass: "text-dark" }, [
      _c("i", { staticClass: "el-icon-collection" }),
      _vm._v("\n                    Knowledge Base Files\n                "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }