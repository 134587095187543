<template>
    <div class="app-body" id="view">
        <div v-if="shouldShowAloAi"
             class="container-listing position-relative">
            <div class="container-header d-flex justify-content-between position-sticky"
                 :style="{top: (top_offset+50) + 'px'}">
                <div class="d-flex">
                    <el-button
                        type="info"
                        size="medium"
                        :disabled="loading"
                        round
                        @click="gotoBots">
                        <i class="fa fa-arrow-left mr-1"></i>
                        <strong>Back</strong>
                    </el-button>

                    <h3 class="listing-heading ml-3">
                        {{ dialogTitle }}
                    </h3>
                </div>

                <div class="d-flex">
                    <el-button type="primary"
                               :disabled="loading"
                               @click="submitForm">
                        {{ bot_id ? 'Save' : 'Create' }}
                    </el-button>
                </div>
            </div>
            <div class="container-fluid pb-3">
                <el-form v-loading="loading">
                    <alo-ai-form-settings ref="form_settings"
                                          :lines="lines"
                                          :bot="bot">
                    </alo-ai-form-settings>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import auth from '../../auth.js'
import {acl_mixin, html_mixin, styling_mixin} from '../../mixins'
import AloAiTable from '../../components/aloai/aloai-table.vue'
import AloAiFormSettings from '../../components/aloai/form/settings.vue'
import * as AloAi from "../../constants/aloai"

export default {
    components: {AloAiFormSettings, AloAiTable},
    mixins: [acl_mixin, html_mixin, styling_mixin],
    props: {
        bot_id: {
            required: false
        }
    },
    data() {
        return {
            loading: false,
            edit_mode: false,
            lines: [],
            bot: {
                name: '',
                description: '',
                opener: '',
                ttl: 10080,
                type: AloAi.TYPE_TEXT,
                direction: AloAi.DIRECTION_INBOUND,
                model: AloAi.MODEL_GPT_4O_MINI,
                voice_model: AloAi.VOICE_MODEL_GPT_4O_MINI_REALTIME_PREVIEW,
                voice: AloAi.VOICE_ALLOY,
                campaign_ids: [],
                instructions: [
                    {
                        role: 'system',
                        content: ''
                    }
                ],
                tools: [],
                uploaded_files: [],
                follow_up_enabled: false,
                follow_up_frequency: 3,
                follow_up_delay: 60, // in seconds
                is_assistant: true,
            },
        }
    },
    computed: {
        dialogTitle() {
            return this.bot_id ? 'Configure the agent' : 'Create a new agent'
        }
    },
    methods: {
        gotoBots() {
            this.$router.push({name: 'AloAi Agents'})
        },
        submitForm() {
            this.loading = true
            let res = this.$refs.form_settings.submitForm()
            if (!res) {
                this.loading = false
                return
            }

            // If edit_mode, update the bot otherwise create a new one
            if (this.edit_mode) {
                this.updateBot()
            } else {
                this.createBot()
            }
        },
        createBot() {
            const bot = this.replaceInstructionsReadOnlyDelimitators()
            axios.post('/api/v1/aloai/bot', bot)
                .then(response => {
                    this.$notify.success({
                        offset: 95,
                        title: 'AloAi Agent',
                        message: 'Agent created successfully.',
                    })

                    // Prevent multiple creations
                    this.bot_id = response.data.id
                    this.edit_mode = true

                    this.$router.push({
                        name: 'AloAi: Configure an agent',
                        params: {
                            bot_id: response.data.id
                        }
                    })
                })
                .catch(error => {
                    const errorMessage = error.response?.data?.message ? `There was an error creating the bot: ${error.response?.data?.message}` : 'There was an error creating the bot'
                    this.$notify.error({
                        title: 'Error',
                        message: errorMessage
                    })
                })
                .finally(() => {
                    this.loading = false
                })
        },
        updateBot() {
            const bot = this.replaceInstructionsReadOnlyDelimitators()
            axios.put(`/api/v1/aloai/bot/${this.bot_id}`, bot)
                .then(response => {
                    this.$notify.success({
                        offset: 95,
                        title: 'AloAi Agent',
                        message: 'Agent updated successfully.',
                    })
                })
                .catch(error => {
                    const errorMessage = error.response?.data?.message ? `There was an error creating the bot: ${error.response?.data?.message}` : 'There was an error creating the bot'
                    this.$notify.error({
                        title: 'Error',
                        message: errorMessage
                    })
                })
                .finally(() => {
                    this.loading = false
                })
        },
        replaceInstructionsReadOnlyDelimitators() {
            // create a deep copy from this.bot
            const bot = JSON.parse(JSON.stringify(this.bot))

            // check if inside bot.instructions[0].content we found '[' or ']' and replace them with \"
            bot.instructions[0].content = this.transformTagsString(bot.instructions[0].content)

            // check if inside bot.instructions[0].content we found '(' or ')' and replace them with \'
            bot.instructions[0].content = this.transformDispositionsString(bot.instructions[0].content)
            return bot
        },
        transformTagsString(input) {
            // Use regular expression to find all occurrences of "{Add [ ... ] tag(s) to the contact}"
            const regex = /\{Add\s+(\[\s*[^\]]+\s*\]\s*)+tag\(s\)\s+to\s+the\s+contact\}/g

            // Replace each occurrence found with the transformed version
            const transformed = input.replace(regex, (match) => {
                // Find all occurrences of "[ ... ]"
                const tagRegex = /\[\s*([^\]]+?)\s*\]/g
                const tags = []
                let tagMatch

                // Extract all tags inside the brackets
                while ((tagMatch = tagRegex.exec(match)) !== null) {
                    tags.push(tagMatch[1].trim())
                }

                // Join the tags with " || "
                const joinedTags = tags.join(' || ')

                // Replace the content of the tags in the original string
                return `{Add " ${joinedTags} " tag(s) to the contact}`
            })

            return transformed
        },
        transformDispositionsString(input) {
            // Use regular expression to find all occurrences of "{Add ( ... ) disposition to the contact}"
            const regex = /\{Add\s+\(\s*[^)]+\s*\)\s+disposition\s+to\s+the\s+contact\}/g

            // Replace each occurrence found with the transformed version
            const transformed = input.replace(regex, (match) => {
                // Find the content inside the parentheses
                const dispositionRegex = /\(\s*([^)]+?)\s*\)/g
                const dispositions = []
                let dispositionMatch

                // Extract the disposition inside the parentheses
                while ((dispositionMatch = dispositionRegex.exec(match)) !== null) {
                    dispositions.push(dispositionMatch[1].trim())
                }

                // Join the dispositions with " || "
                const joinedDispositions = dispositions.join(' || ')

                // Replace the content of the dispositions in the original string
                return `{Add ' ${joinedDispositions} ' disposition to the contact}`
            })

            return transformed
        },
        fetchLines() {
            axios.get('/api/v1/campaign').then(res => {
                this.lines = res.data
            }).catch(err => {
                console.log(err)
            })
        },
        fetchBot() {
            axios.get(`/api/v1/aloai/bot/${this.bot_id}`).then(res => {
                this.bot = res.data
                this.bot.campaign_ids = this.bot.campaigns.length ? this.bot.campaigns.map((campaign) => {
                    return campaign.id
                }) : []
                this.bot.tools = this.bot.tools.length ? this.bot.tools : []
            }).catch(err => {
                console.log(err)
                this.goBack()
            })
        },
        updateTabsHeader() {
            let aloai_tabs_header = document.querySelector('.aloai-agents .el-tabs__header')

            // Check if the element exists to avoid errors
            if (aloai_tabs_header) {
                aloai_tabs_header.style.top = (80 + 50 + this.top_offset) + 'px'
            }
        }
    },
    created() {
        if (!this.shouldShowAloAi) {
            this.goBack()
        }
    },
    mounted() {
        this.setPageTitle(this.$route.name)

        this.updateTabsHeader()

        if (this.bot_id) {
            this.edit_mode = true
            this.fetchBot()
        } else {
            // Type auto-selection
            if (this.$route.query.type) {
                switch (this.$route.query.type) {
                    case 'text':
                        this.bot.type = AloAi.TYPE_TEXT
                        break
                    case 'voice':
                        this.bot.type = AloAi.TYPE_VOICE
                        break
                    default:
                        this.bot.type = AloAi.TYPE_TEXT
                }
            }

            // Direction auto-selection
            if (this.$route.query.direction) {
                switch (this.$route.query.direction) {
                    case 'inbound':
                        this.bot.direction = AloAi.DIRECTION_INBOUND
                        break
                    case 'outbound':
                        this.bot.direction = AloAi.DIRECTION_OUTBOUND
                        break
                    default:
                        this.bot.direction = AloAi.DIRECTION_OUTBOUND
                }
            }
        }

        this.fetchLines()

        // customResize to avoid conflicts with the resize eventListener used by third-party libraries
        // Set the offset
        window.addEventListener('customResize', () => {
            this.updateTabsHeader()
        })
    },
    beforeRouteEnter(to, from, next) {
        // This logic help us wrap the Bots section with the Aloware App components.
        if (to.query.api_token) {
            return next({
                name: 'Login',
                query: {
                    api_token: to.query.api_token
                }
            })
        }

        auth.check().then((res) => {
            if (res.data.user.is_reseller) {
                // redirect to account management portal if the company is a reseller
                next({name: 'Account Management Portal'})
            } else {
                next()
            }
        }).catch((err) => {
            next({
                name: 'Login',
                query: {
                    redirect: to.fullPath
                }
            })
        })
    },
}
</script>

<style scoped>
.container-listing {
    padding: 0 1.5rem;
}

.container-header {
    z-index: 2;
    background: white;
    padding: 20px 0;
    margin-bottom: 0;
}
</style>
