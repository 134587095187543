// Normal call
export const RESOLUTION_NORMAL = 'normal'
// call that went to the queue and had to wait.
export const RESOLUTION_QUEUE = 'queue'
// When caller left a voicemail.
export const RESOLUTION_VOICEMAIL = 'voicemail'
// When call goes to forwarded phone number
export const RESOLUTION_FORWARD = 'forward'
// When caller requested a callback
export const RESOLUTION_CALLBACK_REQUESTED = 'callback-requested'
// When we are processing a callback
export const RESOLUTION_CALLBACK_INITIATED = 'callback-initiated'
// ?
export const RESOLUTION_HUNT_GROUP = 'hunt-group'
// voicemail drop resolution
export const RESOLUTION_VOICEMAIL_DROP = 'voicemail-drop'
// fax resolution
export const RESOLUTION_FAX = 'fax'
// cold transfer resolution
export const RESOLUTION_COLD_TRANSFER = 'cold-transfer'
// queue max wait time reached
export const RESOLUTION_MAX_WAIT = 'max-wait'
// machine resolution
export const RESOLUTION_MACHINE_DETECTED = 'machine-detected'
// alo-ai resolution
export const RESOLUTION_ALOAI = 'alo-ai'

// Normal call
export const RESOLUTION_NORMAL_NEW = 1
// call that went to the queue and had to wait.
export const RESOLUTION_QUEUE_NEW = 2
// When caller left a voicemail.
export const RESOLUTION_VOICEMAIL_NEW = 3
// When call goes to forwarded phone number
export const RESOLUTION_FORWARD_NEW = 4
// When caller requested a callback
export const RESOLUTION_CALLBACK_REQUESTED_NEW = 5
// When we are processing a callback
export const RESOLUTION_CALLBACK_INITIATED_NEW = 6
// ?
export const RESOLUTION_HUNT_GROUP_NEW = 8
// voicemail drop resolution
export const RESOLUTION_VOICEMAIL_DROP_NEW = 9
// fax resolution
export const RESOLUTION_FAX_NEW = 10
// cold transfer resolution
export const RESOLUTION_COLD_TRANSFER_NEW = 11
// queue max wait time reached
export const RESOLUTION_MAX_WAIT_NEW = 12;
// machine resolution
export const RESOLUTION_MACHINE_DETECTED_NEW = 13;
// AloAi resolution
export const RESOLUTION_ALOAI_NEW = 14;
