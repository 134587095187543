var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "dialog-blank",
      attrs: {
        center: "",
        "append-to-body": "",
        "show-close": false,
        "data-testid": "aloai-promotion-dialog",
        visible: _vm.internalDialogVisible,
        "custom-class": "aloai-promotion-dialog",
      },
      on: {
        "update:visible": function ($event) {
          _vm.internalDialogVisible = $event
        },
        close: _vm.hideDialog,
      },
    },
    [
      _c("div", { staticClass: "ai-info-box" }, [
        _c("div", { staticClass: "ai-info-box-header" }, [
          _vm.usagePercentage < 100 && _vm.isTrial
            ? _c("span", { staticClass: "ai-info-box-icon" }, [
                _vm._v("\n                🎁\n            "),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("span", { staticClass: "ai-info-box-title" }, [
            _vm._v(
              "\n                " +
                _vm._s(_vm.modalContent.title) +
                "\n            "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "ai-info-box-content text-white" }, [
          _vm._v(
            "\n            " + _vm._s(_vm.modalContent.message) + "\n        "
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "ai-info-box-links" }, [
          _c("strong", [_vm._v("Guides:")]),
          _vm._v(" "),
          _c("ul", { staticClass: "pl-4" }, [
            _c("li", [
              _c(
                "a",
                {
                  attrs: {
                    href: "https://support.aloware.com/en/articles/10233960-guide-for-agents-using-aloai-voice-analytics",
                    target: "_blank",
                  },
                },
                [
                  _vm._v(
                    "\n                        Agents guide to AloAi Voice Analytics\n                    "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  attrs: {
                    href: "https://support.aloware.com/en/articles/10235067-guide-for-admins-using-aloai-voice-analytics",
                    target: "_blank",
                  },
                },
                [
                  _vm._v(
                    "\n                        Admins guide to AloAi Voice Analytics\n                    "
                  ),
                ]
              ),
            ]),
          ]),
          _vm._v(
            "\n            Revolutionize your calls with AloAi Voice Analytics; read the\n            "
          ),
          _c(
            "a",
            {
              attrs: {
                href: "https://aloware.com/blog/aloai-voice-analytics-announcement",
                target: "_blank",
              },
            },
            [_vm._v("\n                blog post\n            ")]
          ),
          _vm._v("\n            to learn more!\n            "),
          _vm.isAnyCallRecordingDisabled
            ? _c("div", [
                _c("br"),
                _vm._v(" "),
                _c("span", { staticClass: "mr-1" }, [_vm._v("⚠️")]),
                _vm._v(" "),
                _vm.isAllCallRecordingDisabled
                  ? _c("strong", [
                      _vm._v(
                        "You’re currently not recording any calls. To take advantage of our AloAi Voice Analytics product, turn on call recordings:"
                      ),
                    ])
                  : _c("strong", [
                      _vm._v(
                        "You’re currently not recording all your calls. To take advantage of our AloAi Voice Analytics product, turn on call recordings:"
                      ),
                    ]),
                _vm._v(" "),
                _c("ul", { staticClass: "pl-4" }, [
                  _c("li", [
                    _c("span", [_vm._v("For inbound call recordings: ")]),
                    _vm._v(" "),
                    _c("a", { attrs: { href: _vm.inboundCallRecordingUrl } }, [
                      _vm._v("Link"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("span", [_vm._v("For outbound call recordings: ")]),
                    _vm._v(" "),
                    _c("a", { attrs: { href: _vm.outboundCallRecordingUrl } }, [
                      _vm._v("Link"),
                    ]),
                  ]),
                ]),
              ])
            : _vm._e(),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }