var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "app-body", attrs: { id: "view" } }, [
    _vm.shouldShowAloAi
      ? _c("div", { staticClass: "container-listing position-relative" }, [
          _c(
            "div",
            {
              staticClass:
                "container-header d-flex justify-content-between position-sticky",
              style: { top: _vm.top_offset + 50 + "px" },
            },
            [
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "info",
                        size: "medium",
                        disabled: _vm.loading,
                        round: "",
                      },
                      on: { click: _vm.gotoBots },
                    },
                    [
                      _c("i", { staticClass: "fa fa-arrow-left mr-1" }),
                      _vm._v(" "),
                      _c("strong", [_vm._v("Back")]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("h3", { staticClass: "listing-heading ml-3" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.dialogTitle) +
                        "\n                "
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", disabled: _vm.loading },
                      on: { click: _vm.submitForm },
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.bot_id ? "Save" : "Create") +
                          "\n                "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "container-fluid pb-3" },
            [
              _c(
                "el-form",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                },
                [
                  _c("alo-ai-form-settings", {
                    ref: "form_settings",
                    attrs: { lines: _vm.lines, bot: _vm.bot },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }