var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-input",
                {
                  attrs: {
                    type: "number",
                    min: 0,
                    max: 30,
                    placeholder: "Days",
                  },
                  on: { input: _vm.calculateSeconds },
                  model: {
                    value: _vm.days,
                    callback: function ($$v) {
                      _vm.days = _vm._n($$v)
                    },
                    expression: "days",
                  },
                },
                [
                  _c("template", { slot: "append" }, [
                    _vm._v("Day"),
                    _vm.days != 1 ? _c("span", [_vm._v("s")]) : _vm._e(),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-input",
                {
                  attrs: {
                    type: "number",
                    min: 0,
                    max: 23,
                    placeholder: "Hours",
                  },
                  on: { input: _vm.calculateSeconds },
                  model: {
                    value: _vm.hours,
                    callback: function ($$v) {
                      _vm.hours = _vm._n($$v)
                    },
                    expression: "hours",
                  },
                },
                [
                  _c("template", { slot: "append" }, [
                    _vm._v("Hour"),
                    _vm.hours != 1 ? _c("span", [_vm._v("s")]) : _vm._e(),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-input",
                {
                  attrs: {
                    type: "number",
                    min: 0,
                    max: 59,
                    placeholder: "Minutes",
                  },
                  on: { input: _vm.calculateSeconds },
                  model: {
                    value: _vm.minutes,
                    callback: function ($$v) {
                      _vm.minutes = _vm._n($$v)
                    },
                    expression: "minutes",
                  },
                },
                [
                  _c("template", { slot: "append" }, [
                    _vm._v("Minute"),
                    _vm.minutes != 1 ? _c("span", [_vm._v("s")]) : _vm._e(),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }