var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "b-b nav-active-greenish" }, [
      _c("ul", { staticClass: "nav nav-tabs" }, [
        _c("li", { staticClass: "nav-item" }, [
          _c(
            "a",
            {
              staticClass: "border border-right-0 rounded-top-left",
              class: _vm.navClass.read,
              attrs: {
                href: "",
                "data-toggle": "tab",
                "data-target": `#${_vm.type}_tts`,
                "aria-expanded": "true",
              },
            },
            [
              _c("i", { staticClass: "fa fa-robot" }),
              _vm._v("\n          Text to Speech\n        "),
            ]
          ),
        ]),
        _vm._v(" "),
        _vm.allowUploadFile
          ? _c("li", { staticClass: "nav-item" }, [
              _c(
                "a",
                {
                  staticClass: "border rounded-top-right",
                  class: _vm.navClass.play,
                  attrs: {
                    href: "",
                    "data-toggle": "tab",
                    "data-target": `#${_vm.type}_file`,
                    "aria-expanded": "false",
                  },
                },
                [
                  _c("i", { staticClass: "fa fa-play text-md" }),
                  _vm._v("\n          Play Recording\n        "),
                ]
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.file
          ? _c("li", { staticClass: "nav-item" }, [
              _c("a", {
                staticClass: "nav-link",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.deleteFile()
                  },
                },
              }),
            ])
          : _vm._e(),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "tab-content p-a m-b-md border border-top-0" }, [
      _c(
        "div",
        {
          class: _vm.containerClass.read,
          attrs: { id: `${_vm.type}_tts`, "aria-expanded": "true" },
        },
        [
          _c(
            "el-form-item",
            {
              staticClass: "mb-4",
              attrs: { label: _vm.label, prop: _vm.ttsFormItem },
            },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c(
                    "div",
                    { staticClass: "input-group" },
                    [
                      _c("input", {
                        staticClass: "form-control pt-2 pb-2",
                        attrs: { type: "text" },
                        domProps: { value: _vm.tts },
                        on: { input: _vm.emitUpdateEvent },
                      }),
                      _vm._v(" "),
                      _c("variable-dialog"),
                    ],
                    1
                  ),
                ]),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.allowUploadFile
        ? _c(
            "div",
            {
              class: _vm.containerClass.play,
              attrs: { id: `${_vm.type}_file`, "aria-expanded": "false" },
            },
            [
              _vm.file === null
                ? _c("audio-recorder", {
                    staticClass: "audio-recorder-wrapper",
                    attrs: {
                      action: _vm.actionUrl,
                      upload_type: _vm.uploadType,
                    },
                    on: { applyUploadedAudio: _vm.applyUploadedAudio },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "audio-uploader-wrapper" },
                [
                  _vm.file === null
                    ? _c(
                        "el-upload",
                        {
                          attrs: {
                            drag: "",
                            action: _vm.actionUrl,
                            headers: _vm.headers,
                            "on-success": _vm.onSuccess,
                            "on-error": _vm.onFailed,
                            "on-progress": _vm.progressUpload,
                            "before-upload": _vm.beforeUpload,
                            "file-list": _vm.uploadFileList,
                          },
                        },
                        [
                          _c("i", { staticClass: "el-icon-upload" }),
                          _vm._v(" "),
                          _c("div", { staticClass: "el-upload__text" }, [
                            _vm._v("\n            Drop file here or "),
                            _c("em", [_vm._v("click to\n            upload")]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "el-upload__tip",
                              attrs: { slot: "tip" },
                              slot: "tip",
                            },
                            [
                              _vm._v(
                                "\n            MP3/WAV file (less than 8MB)\n          "
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.file === null
                    ? _c("el-progress", {
                        attrs: {
                          "text-inside": true,
                          "stroke-width": 18,
                          percentage: _vm.uploadPercentage,
                          status: _vm.uploadStatus,
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.file !== null
                    ? _c("span", [
                        _c("p", { staticClass: "_600" }, [
                          _vm._v(_vm._s(_vm.label)),
                        ]),
                        _vm._v(" "),
                        _c("audio", { attrs: { controls: "" } }, [
                          _c("source", {
                            attrs: { src: "/static/uploaded_file/" + _vm.file },
                          }),
                          _vm._v(
                            "\n            Your browser does not support the audio element.\n          "
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.file !== null && _vm.allowDeleteFile
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-sm btn-danger pull-right",
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.deleteFile()
                            },
                          },
                        },
                        [
                          _c(
                            "i",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.loading,
                                  expression: "loading",
                                },
                              ],
                              staticClass: "material-icons loader pull-left",
                            },
                            [_vm._v("")]
                          ),
                          _vm._v(" "),
                          _c(
                            "i",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.loading,
                                  expression: "!loading",
                                },
                              ],
                              staticClass: "material-icons pull-left",
                            },
                            [_vm._v("")]
                          ),
                          _vm._v(" "),
                          _c("span", [_vm._v("Remove file")]),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }