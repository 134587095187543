var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasRole("Billing Admin") || _vm.hasRole("Company Admin")
    ? _c(
        "a",
        { attrs: { href: _vm.talkUrl, "data-testid": "talk-bridge-a" } },
        [
          _c(
            "el-button",
            {
              staticClass: "default-app-button talk-button btn btn-xs",
              attrs: {
                type: "primary",
                size: "medium",
                "data-testid": "talk-bridge-el-button",
              },
            },
            [_vm._v("\n        Talk\n    ")]
          ),
        ],
        1
      )
    : _c(
        "a",
        {
          staticStyle: { "vertical-align": "-2px" },
          attrs: { "data-testid": "talk-bridge-a", href: _vm.talkUrl },
          on: {
            click: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "el-dropdown",
            {
              staticClass: "default-app-dropdown talk-button btn btn-xs",
              attrs: {
                "split-button": "",
                type: "primary",
                size: "mini",
                trigger: "click",
              },
              on: { click: _vm.navigateToTalk },
              scopedSlots: _vm._u([
                {
                  key: "dropdown",
                  fn: function () {
                    return [
                      _c(
                        "el-dropdown-menu",
                        {
                          staticClass: "default-app-dropdown",
                          attrs: { "data-testid": "dropdown-menu" },
                        },
                        [
                          _c(
                            "el-dropdown-item",
                            {
                              staticClass: "mb-2",
                              attrs: {
                                disabled: "",
                                "data-testid": "dropdown-menu-item",
                              },
                            },
                            [
                              _c("span", { staticClass: "label-text" }, [
                                _vm._v("Open on Login:"),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-dropdown-item",
                            { attrs: { "data-testid": "dropdown-menu-item" } },
                            [
                              _c(
                                "el-radio",
                                {
                                  attrs: {
                                    "data-testid": "talk-bridge-radio",
                                    label: _vm.AppTypes.APP_ALOWARE_TALK,
                                  },
                                  on: { change: _vm.updateDefaultApp },
                                  model: {
                                    value: _vm.auth.user.profile.default_app,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.auth.user.profile,
                                        "default_app",
                                        $$v
                                      )
                                    },
                                    expression: "auth.user.profile.default_app",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(_vm.alowareTalk) +
                                      "\n                    "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-dropdown-item",
                            { attrs: { "data-testid": "dropdown-menu-item" } },
                            [
                              _c(
                                "el-radio",
                                {
                                  attrs: {
                                    "data-testid": "talk-bridge-radio",
                                    label: _vm.AppTypes.APP_ALOWARE_CLASSIC,
                                  },
                                  on: { change: _vm.updateDefaultApp },
                                  model: {
                                    value: _vm.auth.user.profile.default_app,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.auth.user.profile,
                                        "default_app",
                                        $$v
                                      )
                                    },
                                    expression: "auth.user.profile.default_app",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(_vm.alowareClassic) +
                                      "\n                    "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [_vm._v("\n        Talk\n        ")]
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }