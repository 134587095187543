<template>
    <div class="app-body"
         id="view"
         v-if="communication">
        <div class="header-padding header-info"
             v-if="hasPermissionTo(['view communication'])">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-12">
                    <div class="box b-a no-effect">
                        <div class="box-header">
                            <div class="d-flex flex-row px-3 justify-content-between">
                                <div>
                                    <b>Communication Info</b>
                                </div>

                                <div class="width-200 fixed">
                                    <!-- Archive communication -->
                                    <el-button class="opaq pull-right"
                                               style="padding: 7px 12px;border-radius: 8px;font-size: 15px !important;"
                                               type="danger"
                                               v-if="hasPermissionTo('archive communication')"
                                               :loading="loading_archive"
                                               :disabled="loading_archive"
                                               @click="archiveCommunication(communication_id)">
                                        Archive
                                    </el-button>
                                </div>
                            </div>
                            <div class="pl-3 pr-3">
                            </div>
                        </div>
                        <div class="box-divider m-a-0"></div>
                        <div class="box-body">
                            <communication-info :communication="communication"
                                                :verbose="true">
                            </communication-info>
                        </div>
                    </div>
                </div>
                <div class="col-lg-8 col-md-6 col-12"
                     v-if="communication && communication.type == CommunicationTypes.CALL">
                    <div class="box b-a no-effect">
                        <div class="box-body">
                            <el-tabs v-model="tab_name"
                                     class="pl-2 pr-2">
                                <el-tab-pane label="Ring Group Snapshot"
                                             name="ring_group_snapshot">
                                    <template v-if="showRingGroupSnapShot">
                                        <div class="text-center text-md _400">
                                            <p class="mb-0">This is a snapshot of the ring group at the point when this call arrived<span v-if="communication.metadata.ring_group_snapshot.length > 0 && communication.metadata.ring_group_snapshot[0].created_at"> {{ communication.metadata.ring_group_snapshot[0].created_at | fixCommunicationDateTime }}</span>.</p>
                                        </div>
                                        <ring-group-routing-display :ring_group="ringGroup"
                                                                    :layers="communication.metadata.ring_group_snapshot">
                                        </ring-group-routing-display>
                                    </template>
                                    <template v-else>
                                        <div class="text-center text-md _400">
                                            <p v-if="communication.disposition_status2 === CommunicationDispositionStatus.DISPOSITION_STATUS_ABANDONED_NEW"
                                               class="mb-0">
                                                Abandoned calls don't have a ring group snapshot.
                                            </p>
                                            <p v-else
                                               class="mb-0">
                                                {{ !ringGroup ? 'This call does not have a ring group.' : 'This feature is not yet available on your account.' }}
                                            </p>
                                        </div>
                                    </template>
                                </el-tab-pane>
                            </el-tabs>
                        </div>
                    </div>
                    <div class="box border no-effect mt-3">
                        <div class="box-body">
                            <h5 class="text-dark mx-4 my-3">Call Quality</h5>
                            <template v-if="showNetworkLogs">
                                <network-logs-display :callIssues="Object.values(communication.call_quality_summary)"
                                                      :user="communication.user"/>
                            </template>
                            <template v-else>
                                <div class="d-flex align-items-center _600 border-top px-4 pt-3">
                                    <img src="/assets/images/app-icons/icon-check-circle-green.svg"
                                         class="mr-2">
                                    No call quality issues detected
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <el-backtop target="html"
                    ref="backtop"
                    :bottom="20"
                    :right="20">
        </el-backtop>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import auth from '../auth'
import {chargebee_mixin, scroll_mixin} from '../mixins'
import CommunicationSummary from "../components/communication-summary"
import RingGroupRoutingDisplay from "../components/ring-groups/ring-group-routing-display"
import * as CommunicationTypes from '../constants/communication-types'
import * as CommunicationDispositionStatus from '../constants/communication-disposition-status'
import NetworkLogsDisplay from "../components/network-logs/network-logs-display"

export default {
    components: {CommunicationSummary, NetworkLogsDisplay, RingGroupRoutingDisplay},

    mixins: [chargebee_mixin, scroll_mixin],

    props: {
        communication_id: {
            required: true
        }
    },

    data() {
        return {
            auth: auth,
            loading_archive: false,
            communication: null,
            tab_name: 'ring_group_snapshot',
            CommunicationTypes,
            CommunicationDispositionStatus,
        }
    },

    computed: {
        ...mapState(['ring_groups']),
        ...mapState('cache', ['current_company']),

        ringGroup() {
            if (this.communication.ring_group_id) {
                return this.getRingGroup(this.communication.ring_group_id)
            }

            if (this.communication.metadata && this.communication.metadata.added_ring_group_id) {
                return this.getRingGroup(this.communication.metadata.added_ring_group_id)
            }

            return null
        },

        showRingGroupSnapShot() {
            return this.communication.metadata &&
                this.communication.metadata.ring_group_snapshot &&
                this.ringGroup
        },

        showNetworkLogs() {
            return Object.values(this.communication?.call_quality_summary).length > 0
        }
    },

    created() {
        VueEvent.listen('update_communication', (data) => {
            // check communication update matches
            if (this.communication && this.communication.id === data.id) {
                // update communication
                data = _.merge(this.communication, data)
                this.communication = data
            }
        })
    },

    mounted() {
        this.setTitle()
        if (this.hasPermissionTo('view communication')) {
            this.getCommunication(this.communication_id)
        }
    },

    methods: {
        setTitle() {
            axios.get('/get-statics')
                .then(res => {
                    let name = res.data.name
                    this.setPageTitle('Communication - ' + name)
                })
                .catch(err => {
                    this.setPageTitle('Communication - Aloware')
                    console.log(err)
                    this.$root.handleErrors(err.response)
                })
        },

        getRingGroup(id) {
            if (!id) {
                return null
            }
            let found = this.ring_groups.find(ring_group => ring_group.id === id)
            if (found) {
                return found
            }

            return null
        },

        getCommunication(communication_id) {
            axios.get('/api/v1/communication/' + communication_id, {
                mode: 'no-cors',
            }).then(res => {
                this.communication = res.data
            }).catch(err => {
                console.log(err)
                this.$root.handleErrors(err.response)
            })
        },


        archiveCommunication(communication_id) {
            this.$confirm('Archiving communication will remove it from all reports and plots. Continue?', 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                customClass: 'width-500 fixed',
                type: 'warning'
            }).then(() => {
                this.deleteCommunication(communication_id)
            }).catch(() => {
                // archive canceled
            })
        },

        deleteCommunication(communication_id) {
            this.loading_archive = true
            axios.delete(`/api/v1/communication/${communication_id}`).then(res => {
                this.loading_archive = false
                // trigger event manually to remove the communication from the list
                VueEvent.fire('delete_communication', {id: communication_id})
                // send an notification to inform user the communication is archived
                this.$notify({
                    offset: 95,
                    title: 'Communication',
                    message: 'Communication archived successfully.',
                    type: 'success',
                    showClose: true
                })
                // go back
                this.goBack()
            }).catch(err => {
                this.loading_archive = false
                this.$root.handleErrors(err.response)
            })
        },

        reset() {
            this.communication = null
        },
    },


    watch: {
        $route({params, query}) {
            this.reset()
            if (this.hasPermissionTo('view communication')) {
                this.getCommunication(this.communication_id)
            }
        }
    },

    beforeRouteEnter(to, from, next) {
        auth.check().then((res) => {
            if (res.data.user.is_reseller) {
                // redirect to account management portal if the company is a reseller
                next({name: 'Account Management Portal'})
            } else {
                next()
            }
        }).catch((err) => {
            next({name: 'Login', query: {redirect: to.fullPath}})
        })
    }
}
</script>
