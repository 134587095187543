var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "_400" },
    _vm._l(_vm.filteredCallIssues, function (callIssue) {
      return _c("div", [
        _c("div", { staticClass: "border-top text-left px-4 py-3" }, [
          _c("label", { staticClass: "form-control-label" }, [
            _vm._v(" Agent: "),
          ]),
          _vm._v(
            "\n            " +
              _vm._s(_vm.user?.full_name ?? "-") +
              "\n            "
          ),
          callIssue.event_name === _vm.RTT_EVENT
            ? _c(
                "div",
                [
                  _c("network-signal-strength", {
                    attrs: {
                      "value-issue": callIssue.avg,
                      issue: _vm.networkIssues[callIssue.event_name],
                      title: _vm.networkIssues[callIssue.event_name].trigger,
                    },
                  }),
                  _vm._v(" "),
                  _c("label", { staticClass: "form-control-label" }, [
                    _vm._v("Avg outbound Latency:"),
                  ]),
                  _vm._v(
                    "\n                " +
                      _vm._s(callIssue.avg) +
                      " ms\n                "
                  ),
                  _c("br"),
                  _vm._v(" "),
                  _c("label", { staticClass: "form-control-label" }, [
                    _vm._v("Occurrences:"),
                  ]),
                  _vm._v(
                    "\n                " +
                      _vm._s(callIssue.count) +
                      " times\n                "
                  ),
                  _c("br"),
                  _c("br"),
                  _vm._v(
                    "\n\n                " +
                      _vm._s(_vm.networkIssues[callIssue.event_name].details) +
                      "\n            "
                  ),
                ],
                1
              )
            : callIssue.event_name === _vm.MOS_EVENT
            ? _c(
                "div",
                [
                  _c("network-signal-strength", {
                    attrs: {
                      "value-issue": callIssue.avg,
                      issue: _vm.networkIssues[callIssue.event_name],
                      title: _vm.networkIssues[callIssue.event_name].trigger,
                    },
                  }),
                  _vm._v(" "),
                  _c("label", { staticClass: "form-control-label" }, [
                    _vm._v("Outbound MOS:"),
                  ]),
                  _vm._v(
                    "\n                " +
                      _vm._s(callIssue.avg) +
                      "\n                "
                  ),
                  _c("br"),
                  _vm._v(" "),
                  _c("label", { staticClass: "form-control-label" }, [
                    _vm._v("Occurrences:"),
                  ]),
                  _vm._v(
                    "\n                " +
                      _vm._s(callIssue.count) +
                      " times\n                "
                  ),
                  _c("br"),
                  _c("br"),
                  _vm._v(
                    "\n\n                " +
                      _vm._s(_vm.networkIssues[callIssue.event_name].details) +
                      "\n            "
                  ),
                ],
                1
              )
            : callIssue.event_name === _vm.JITTER_EVENT
            ? _c(
                "div",
                [
                  _c("network-signal-strength", {
                    attrs: {
                      "value-issue": callIssue.avg,
                      issue: _vm.networkIssues[callIssue.event_name],
                      title: _vm.networkIssues[callIssue.event_name].trigger,
                    },
                  }),
                  _vm._v(" "),
                  _c("label", { staticClass: "form-control-label" }, [
                    _vm._v("Avg inbound Jitter:"),
                  ]),
                  _vm._v(
                    "\n                " +
                      _vm._s(callIssue.avg) +
                      " ms\n                "
                  ),
                  _c("br"),
                  _vm._v(" "),
                  _c("label", { staticClass: "form-control-label" }, [
                    _vm._v("Occurrences:"),
                  ]),
                  _vm._v(
                    "\n                " +
                      _vm._s(callIssue.count) +
                      " times\n                "
                  ),
                  _c("br"),
                  _c("br"),
                  _vm._v(
                    "\n\n                " +
                      _vm._s(_vm.networkIssues[callIssue.event_name].details) +
                      "\n            "
                  ),
                ],
                1
              )
            : callIssue.event_name === _vm.BYTES_RECEIVED_EVENT
            ? _c(
                "div",
                [
                  _c("network-signal-strength", {
                    attrs: {
                      "value-issue": callIssue.avg,
                      issue: _vm.networkIssues[callIssue.event_name],
                      title: _vm.networkIssues[callIssue.event_name].trigger,
                    },
                  }),
                  _vm._v(" "),
                  _c("label", { staticClass: "form-control-label" }, [
                    _vm._v("Bytes received:"),
                  ]),
                  _vm._v(
                    "\n                " +
                      _vm._s(callIssue.avg) +
                      "\n                "
                  ),
                  _c("br"),
                  _vm._v(" "),
                  _c("label", { staticClass: "form-control-label" }, [
                    _vm._v("Occurrences:"),
                  ]),
                  _vm._v(
                    "\n                " +
                      _vm._s(callIssue.count) +
                      " times\n                "
                  ),
                  _c("br"),
                  _c("br"),
                  _vm._v(
                    "\n\n                " +
                      _vm._s(_vm.networkIssues[callIssue.event_name].details) +
                      "\n            "
                  ),
                ],
                1
              )
            : callIssue.event_name === _vm.BYTES_SENT_EVENT
            ? _c(
                "div",
                [
                  _c("network-signal-strength", {
                    attrs: {
                      "value-issue": callIssue.avg,
                      issue: _vm.networkIssues[callIssue.event_name],
                      title: _vm.networkIssues[callIssue.event_name].trigger,
                    },
                  }),
                  _vm._v(" "),
                  _c("label", { staticClass: "form-control-label" }, [
                    _vm._v("Bytes sent:"),
                  ]),
                  _vm._v(
                    "\n                " +
                      _vm._s(callIssue.avg) +
                      "\n                "
                  ),
                  _c("br"),
                  _vm._v(" "),
                  _c("label", { staticClass: "form-control-label" }, [
                    _vm._v("Occurrences:"),
                  ]),
                  _vm._v(
                    "\n                " +
                      _vm._s(callIssue.count) +
                      " times\n                "
                  ),
                  _c("br"),
                  _c("br"),
                  _vm._v(
                    "\n\n                " +
                      _vm._s(_vm.networkIssues[callIssue.event_name].details) +
                      "\n            "
                  ),
                ],
                1
              )
            : callIssue.event_name === _vm.HIGH_PACKET_LOSS_EVENT
            ? _c(
                "div",
                [
                  _c("network-signal-strength", {
                    attrs: {
                      "value-issue": callIssue.avg,
                      issue: _vm.networkIssues[callIssue.event_name],
                      title: _vm.networkIssues[callIssue.event_name].trigger,
                    },
                  }),
                  _vm._v(" "),
                  _c("label", { staticClass: "form-control-label" }, [
                    _vm._v("Inbound Packet Loss:"),
                  ]),
                  _vm._v(
                    "\n                " +
                      _vm._s(callIssue.avg) +
                      "%\n                "
                  ),
                  _c("br"),
                  _vm._v(" "),
                  _c("label", { staticClass: "form-control-label" }, [
                    _vm._v("Occurrences:"),
                  ]),
                  _vm._v(
                    "\n                " +
                      _vm._s(callIssue.count) +
                      " times\n                "
                  ),
                  _c("br"),
                  _c("br"),
                  _vm._v(
                    "\n\n                " +
                      _vm._s(_vm.networkIssues[callIssue.event_name].details) +
                      "\n            "
                  ),
                ],
                1
              )
            : callIssue.event_name === _vm.HIGH_PACKETS_LOST_FRACTION_EVENT
            ? _c(
                "div",
                [
                  _c("network-signal-strength", {
                    attrs: {
                      "value-issue": callIssue.avg,
                      issue: _vm.networkIssues[callIssue.event_name],
                      title: _vm.networkIssues[callIssue.event_name].trigger,
                    },
                  }),
                  _vm._v(" "),
                  _c("label", { staticClass: "form-control-label" }, [
                    _vm._v("Inbound Packet Loss Fraction:"),
                  ]),
                  _vm._v(
                    "\n                " +
                      _vm._s(callIssue.avg) +
                      "%\n                "
                  ),
                  _c("br"),
                  _vm._v(" "),
                  _c("label", { staticClass: "form-control-label" }, [
                    _vm._v("Occurrences:"),
                  ]),
                  _vm._v(
                    "\n                " +
                      _vm._s(callIssue.count) +
                      " times\n                "
                  ),
                  _c("br"),
                  _c("br"),
                  _vm._v(
                    "\n\n                " +
                      _vm._s(_vm.networkIssues[callIssue.event_name].details) +
                      "\n            "
                  ),
                ],
                1
              )
            : _vm._e(),
        ]),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }