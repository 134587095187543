<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <h5 class="text-dark">
                    <i class="el-icon-top-right"></i>
                    <span v-if="bot.type === AloAi.TYPE_TEXT && bot.direction === AloAi.DIRECTION_OUTBOUND">Opener Message</span>
                    <span v-else>Greeting Message</span>
                </h5>

                <el-form-item prop="opener">
                    <div class="form-label">
                        <small v-if="bot.type === AloAi.TYPE_TEXT">
                            The first message the agent sends to the contact. Use variables to personalize it.
                        </small>
                        <small v-else>
                            The first message the agent says to the contact. Use variables to personalize it.
                        </small>
                    </div>
                    <el-input v-model="bot.opener"
                              type="textarea"
                              @input="preValidateForm('agent_settings')">
                    </el-input>
                    <el-tooltip content="Add variable"
                                placement="top">
                        <variables @variable-selected="variableSelected"/>
                    </el-tooltip>
                    <hr class="mb-0 mt-1">
                </el-form-item>
            </div>
            <div class="col-md-12">
                <h5 class="text-dark">
                    <i class="el-icon-document"></i>
                    Instructions
                </h5>
                <el-form-item prop="instructions">
                    <div class="form-label">
                        <small>
                            Write a personality for the agent and try to explain what you want the agent to perform in details.
                        </small>
                    </div>

                    <el-input class="w-100 aloai-instructions"
                              type="textarea"
                              ref="textareaInput"
                              v-model="bot.instructions[0].content"
                              :rows="14"
                              :readonly="loadingTags"
                              :autosize="{ minRows: 14 }"
                              @input="emitValidate">
                    </el-input>

                    <div class="d-flex align-items-center"
                         v-if="bot.type == AloAi.TYPE_TEXT">
                        <el-tooltip content="Add Disposition"
                                    placement="top">
                            <dispositions-dropdown :key="dispositionsDropdownKey"
                                                   :selected-disposition="currentDisposition"
                                                   @disposition-selected="dispositionSelected">
                            </dispositions-dropdown>
                        </el-tooltip>

                        <el-tooltip content="Add Tags"
                                    placement="top">
                            <tags-dropdown :key="tagsDropdownKey"
                                           :custom-tags="currentTags"
                                           :selected-tags-list="currentTagsIds"
                                           :disabled="loadingTags"
                                           @tags-selected="tagsSelected">
                            </tags-dropdown>
                        </el-tooltip>

                        <el-tooltip content="Add Lists"
                                    placement="top">
                            <lists-dropdown :custom-lists="currentLists"
                                            :selected-list-ids="currentListsIds"
                                            @lists-selected="listsSelected">
                            </lists-dropdown>
                        </el-tooltip>

                        <el-tooltip content="Add Disengage Trigger"
                                    placement="top">
                            <el-button
                                size="mini"
                                circle
                                class="border-0 bg-transparent"
                                @click="addDisengageTrigger"
                                style="margin-left: -2px;"
                            >
                                <i
                                    class="fa fa-ellipsis-h"
                                    style="
                                        padding: 3px;
                                        border-radius: 50%;
                                        font-size: 10px;
                                        color: rgb(186, 186, 186);
                                        border: 1px solid rgb(186, 186, 186);
                                    "
                                ></i>
                            </el-button>
                        </el-tooltip>
                    </div>
                    <hr class="mb-0 mt-1">
                </el-form-item>
            </div>
        </div>
        <template v-if="bot.type === AloAi.TYPE_TEXT">
            <div class="row">
                <div class="col-md-12">
                    <h5 class="text-dark">
                        <i class="el-icon-collection"></i>
                        Knowledge Base Files
                    </h5>
                    <el-form-item class="mb-0">
                        <div class="form-label">
                            <small>
                                Upload the docs you want the bot to use as knowledge base. Supported formats are: docx, html, json, md, pdf, pptx, txt
                            </small>
                        </div>

                        <multi-upload
                            v-if="bot.is_assistant && bot.model !== AloAi.MODEL_GPT_35_T"
                            :action="getAction"
                            :delete-action="deleteAction"
                            :files="uploaded_files"
                            @change="uploadedFilesChanged">
                        </multi-upload>
                        <p class="text-md _400 text-danger"
                           v-if="!bot.is_assistant">
                            This is a bot, reach out to support to upgrade it to an assistant bot to use this feature.
                        </p>
                        <p class="text-md _400 text-danger"
                           v-if="bot.model === AloAi.MODEL_GPT_35_T">
                            You can't have KB files with this model.
                        </p>
                    </el-form-item>
                </div>
            </div>
        </template>
    </div>
</template>
<script>
import {form_validation_mixin} from '../../../mixins'
import FormFunctions from './function/index.vue'
import FormInstructions from './instructions.vue'
import TagsDropdown from './tags.vue'
import DispositionsDropdown from './dispositions.vue'
import MultiUpload from '../../multi-upload.vue'
import cloneDeep from 'lodash/cloneDeep'
import ListsDropdown from './lists.vue'
import Variables from '../../messenger/variables.vue'
import * as AloAi from '../../../constants/aloai'

export default {
    name: 'AloAiSettings',

    mixins: [
        form_validation_mixin
    ],

    components: {
        MultiUpload,
        FormFunctions,
        FormInstructions,
        TagsDropdown,
        DispositionsDropdown,
        ListsDropdown,
        Variables,
    },

    props: {
        bot: {
            required: true,
        },
    },

    data() {
        return {
            uploaded_files: this.bot.uploaded_files,

            tags: [],
            currentTags: [], // To store tags for the current section
            currentTagsIds: [],
            readonlySections: [],
            currentSection: {},
            cursorPosition: 0,
            currentDisposition: {},
            tagsDropdownKey: 0, // Key to force re-render of tags-dropdown
            dispositionsDropdownKey: 0,
            isEditingReadonlySection: false,
            loadingTags: false,
            AloAi,
            lists: [],
            currentLists: [],
            currentListsIds: [],
        }
    },

    computed: {
        getAction() {
            return this.bot.id ? '/api/v1/aloai/bot/' + this.bot.id + '/upload-file' : '/api/v1/aloai/upload-file'
        },

        deleteAction() {
            return this.bot.id ? '/api/v1/aloai/bot/' + this.bot.id + '/delete-file' : '/api/v1/aloai/delete-file'
        },
    },

    methods: {
        uploadedFilesChanged(files) {
            this.bot.uploaded_files = files
        },

        dispositionSelected(disposition) {
            if (!disposition) {
                // check if cursor is withing any readonly section and remove it
                this.removeReadonlySection()
                return
            }

            const dispositionName = `${disposition.id}: ${disposition.name}`
            const dispositionNameTemplate = `( ${disposition.id}: ${disposition.name} )`
            let newContent = this.bot.instructions[0].content

            // Check if cursor is within any readonly section
            let updated = false

            const updatedSectionData = this.updateReadOnlySectionDispositionContent(newContent, disposition, dispositionName, dispositionNameTemplate)
            updated = updatedSectionData.isUpdated
            newContent = updatedSectionData.newContent

            // If no section was updated, insert the disposition at the cursor position
            if (!updated) {
                newContent = this.createReadOnlySectionDispositionContent(newContent, disposition, dispositionName, dispositionNameTemplate)
            }

            this.bot.instructions[0].content = newContent
            this.collectSpecialInstructionsToSave()
        },

        async tagsSelected(tags) {
            if (!tags.length) {
                // check if cursor is withing any readonly section and remove it
                this.removeReadonlySection()
                return
            }

            await this.getTags(tags)

            // join the tags name into a separated string with each tag wrapped in square brackets
            const joinedTags = this.tags.map((tag) => `[ ${tag.name} ]`).join(' ')

            let newContent = this.bot.instructions[0].content

            // Check if cursor is within any readonly section
            let updated = false
            const updatedSectionData = this.updateReadOnlySectionTagsContent(newContent, joinedTags)
            updated = updatedSectionData.isUpdated
            newContent = updatedSectionData.newContent

            // If no section was updated, insert the tags at the cursor position
            if (!updated) {
                newContent = this.createReadOnlySectionTagsContent(newContent, joinedTags)
            }

            this.bot.instructions[0].content = newContent
            this.collectSpecialInstructionsToSave()
        },

        listsSelected(listIds, listItems) {
            if (!listIds.length) {
                // check if cursor is withing any readonly section and remove it
                this.removeReadonlySection()
                return
            }

            // join the tags name into a separated string with each tag wrapped in square brackets
            const joinedLists = listItems.filter((item) => listIds.includes(item.id)).map((item) => {
                return `[ ${item.name} ]`
            }).join(' ')

            let newContent = this.bot.instructions[0].content

            // Check if cursor is within any readonly section
            let updated = false
            const updatedSectionData = this.updateReadOnlySectionListsContent(newContent, joinedLists)
            updated = updatedSectionData.isUpdated
            newContent = updatedSectionData.newContent

            // If no section was updated, insert the tags at the cursor position
            if (!updated) {
                newContent = this.createReadOnlySectionListsContent(newContent, joinedLists)
            }

            this.bot.instructions[0].content = newContent
            this.collectSpecialInstructionsToSave()
        },

        adjustReadonlySections(startPosition, lengthToAdd) {
            this.readonlySections.forEach(section => {
                if (section.start > startPosition) {
                    section.start += lengthToAdd
                    section.end += lengthToAdd
                }
            })
        },

        createReadOnlySectionTagsContent(newContent, joinedTags) {
            this.isEditingReadonlySection = true
            newContent = newContent.slice(0, this.cursorPosition) + `{Add ${joinedTags} tag(s) to the contact}` + newContent.slice(this.cursorPosition)

            const tagsTemplateLenght = `{Add ${joinedTags} tag(s) to the contact}`.length
            const endSection = this.cursorPosition + tagsTemplateLenght
            const tagsInUse = [...this.tags]
            const section = {
                start: this.cursorPosition,
                end: endSection,
                tags: tagsInUse,
                tagNames: this.transformTagsString(joinedTags)
            }

            // Update readonlySections with the new section
            this.readonlySections.push(section)
            this.cursorPosition = endSection
            this.currentSection = cloneDeep(section)

            // Adjust the start and end positions of the following sections
            this.adjustReadonlySections(this.currentSection.start, tagsTemplateLenght)

            return newContent
        },

        updateReadOnlySectionTagsContent(newContent, joinedTags) {
            let updated = false
            let endPositionDiff = 0
            const contentAdded = `{Add ${joinedTags} tag(s) to the contact}`

            for (const section of this.readonlySections) {
                if (this.cursorPosition >= section.start && this.cursorPosition <= section.end) {
                    this.isEditingReadonlySection = true
                    const endPositionBeforeUpdate = section.end
                    const tagsInUse = [...this.tags]

                    newContent = newContent.slice(0, section.start) + contentAdded + newContent.slice(section.end)
                    section.tags = tagsInUse
                    section.tagNames = this.transformTagsString(joinedTags)
                    section.end = section.start + contentAdded.length

                    endPositionDiff = section.end - endPositionBeforeUpdate
                    this.cursorPosition = section.end
                    this.currentSection = cloneDeep(section)
                    updated = true

                    // Adjust the start and end positions of the following sections
                    this.adjustReadonlySections(this.currentSection.start, endPositionDiff)
                    break
                }
            }

            return {
                isUpdated: updated,
                newContent: newContent
            }
        },

        updateReadOnlyDisengageContactSection(newContent) {
            let isUpdated = false
            let endPositionDiff = 0
            const contentAdded = `{Disengage with the contact}`

            for (const section of this.readonlySections) {
                if (this.cursorPosition >= section.start && this.cursorPosition <= section.end) {
                    this.isEditingReadonlySection = true
                    const endPositionBeforeUpdate = section.end

                    newContent = newContent.slice(0, section.start) + contentAdded + newContent.slice(section.end)
                    section.end = section.start + contentAdded.length
                    section.disengageContact = true

                    endPositionDiff = section.end - endPositionBeforeUpdate
                    this.cursorPosition = section.end
                    this.currentSection = cloneDeep(section)
                    isUpdated = true

                    // Adjust the start and end positions of the following sections
                    this.adjustReadonlySections(this.currentSection.start, endPositionDiff)
                    break
                }
            }

            return {
                isUpdated,
                newContent,
            }
        },

        createReadOnlySectionDispositionContent(newContent, disposition, dispositionName, dispositionNameTemplate) {
            this.isEditingReadonlySection = true
            newContent = newContent.slice(0, this.cursorPosition) + `{Add ${dispositionNameTemplate} disposition to the contact}` + newContent.slice(this.cursorPosition)

            const dispositionTemplateLenght = `{Add ${dispositionNameTemplate} disposition to the contact}`.length
            const endSection = this.cursorPosition + dispositionTemplateLenght
            const section = {
                start: this.cursorPosition,
                end: endSection,
                disposition: cloneDeep(disposition),
                dispositionName: dispositionName
            }

            // Update readonlySections with the new section
            this.readonlySections.push(section)
            this.cursorPosition = endSection
            this.currentSection = cloneDeep(section)

            // Adjust the start and end positions of the following sections
            this.adjustReadonlySections(this.currentSection.start, dispositionTemplateLenght)

            return newContent
        },

        updateReadOnlySectionDispositionContent(newContent, disposition, dispositionName, dispositionNameTemplate) {
            let updated = false
            let endPositionDiff = 0
            const contentAdded = `{Add ${dispositionNameTemplate} disposition to the contact}`

            for (const section of this.readonlySections) {
                if (this.cursorPosition >= section.start && this.cursorPosition <= section.end) {
                    this.isEditingReadonlySection = true
                    const endPositionBeforeUpdate = section.end

                    newContent = newContent.slice(0, section.start) + contentAdded + newContent.slice(section.end)
                    section.disposition = disposition
                    section.dispositionName = dispositionName
                    section.end = section.start + contentAdded.length

                    endPositionDiff = section.end - endPositionBeforeUpdate
                    this.cursorPosition = section.end
                    this.currentSection = cloneDeep(section)
                    updated = true

                    // Adjust the start and end positions of the following sections
                    this.adjustReadonlySections(this.currentSection.start, endPositionDiff)
                    break
                }
            }

            return {
                isUpdated: updated,
                newContent: newContent
            }
        },

        createReadOnlySectionListsContent(newContent, joinedLists) {
            const instruction = `{Add contact to ${joinedLists} list(s)}`

            this.isEditingReadonlySection = true
            newContent = newContent.slice(0, this.cursorPosition) + instruction + newContent.slice(this.cursorPosition)

            const instructionLength = instruction.length
            const endSection = this.cursorPosition + instructionLength
            const listsInUse = [...this.lists]
            const section = {
                start: this.cursorPosition,
                end: endSection,
                lists: listsInUse,
                listNames: this.transformListsString(joinedLists)
            }

            // Update readonlySections with the new section
            this.readonlySections.push(section)
            this.cursorPosition = endSection
            this.currentSection = cloneDeep(section)

            // Adjust the start and end positions of the following sections
            this.adjustReadonlySections(this.currentSection.start, instructionLength)

            return newContent
        },

        updateReadOnlySectionListsContent(newContent, joinedLists) {
            let updated = false
            let endPositionDiff = 0
            const contentAdded = `{Add contact to ${joinedLists} list(s)}`

            for (const section of this.readonlySections) {
                if (this.cursorPosition >= section.start && this.cursorPosition <= section.end) {
                    this.isEditingReadonlySection = true
                    const endPositionBeforeUpdate = section.end
                    const listsInUse = [...this.lists]

                    newContent = newContent.slice(0, section.start) + contentAdded + newContent.slice(section.end)
                    section.lists = listsInUse
                    section.listNames = this.transformListsString(joinedLists)
                    section.end = section.start + contentAdded.length

                    endPositionDiff = section.end - endPositionBeforeUpdate
                    this.cursorPosition = section.end
                    this.currentSection = cloneDeep(section)
                    updated = true

                    // Adjust the start and end positions of the following sections
                    this.adjustReadonlySections(this.currentSection.start, endPositionDiff)
                    break
                }
            }

            return {
                isUpdated: updated,
                newContent: newContent
            }
        },

        removeReadonlySection() {
            // check if cursor is withing any readonly section and remove it
            for (const section of this.readonlySections) {
                if (this.cursorPosition >= section.start && this.cursorPosition <= section.end) {
                    this.bot.instructions[0].content = this.bot.instructions[0].content.slice(0, section.start) + this.bot.instructions[0].content.slice(section.end)
                    this.cursorPosition = section.start

                    //remove the section
                    this.readonlySections = this.readonlySections.filter(s => s.start !== section.start)
                    this.resetTagsDropdown()
                    this.resetDispositionsDropdown()
                    this.updateReadonlySections()
                    break
                }
            }
        },

        transformTagsString(input) {
            // Use regular expression to find the occurrences of " ] [ "
            const regex = /] \[ /g

            return input
                .replace(regex, '|| ') // Replace " ] [ " with " || "
                .replace(/\[|\]/g, '') // Remove remaining brackets
        },

        reverseTransformTagsString(input) {
            // Use regular expression to find all occurrences of "{Add "..." tag(s) to the contact}"
            const regex = /\{Add\s+"([^"]+)"\s+tag\(s\)\s+to\s+the\s+contact\}/g

            // Replace each occurrence found with the transformed version
            const transformed = input.replace(regex, (match, p1) => {
                // Split tags by " || "
                const tags = p1.split('||').map(tag => tag.trim())

                // Create the new string with square brackets around each tag
                const bracketedTags = tags.map(tag => `[ ${tag} ]`).join(' ')

                // Replace the content of the tags in the original string
                return `{Add ${bracketedTags} tag(s) to the contact}`
            })

            return transformed
        },

        reverseTransformDispositionsString(input) {
            // Use regular expression to find all occurrences of "{Add '...' disposition to the contact}"
            const regex = /\{Add\s+'([^']+)'\s+disposition\s+to\s+the\s+contact\}/g

            // Replace each occurrence found with the transformed version
            const transformed = input.replace(regex, (match, p1) => {
                // Create the new string with parentheses around the disposition
                const parenthesizedDisposition = `( ${p1.trim()} )`

                // Replace the content of the disposition in the original string
                return `{Add ${parenthesizedDisposition} disposition to the contact}`
            })

            return transformed
        },

        transformListsString(input) {
            // Use regular expression to find the occurrences of " ] [ "
            const regex = /] \[ /g

            return input
                .replace(regex, '|| ') // Replace " ] [ " with " || "
                .replace(/\[|\]/g, '') // Remove remaining brackets
        },

        reverseTransformDisengageContact(input) {
            return input.replace(/\{Disengage with contact, STOP replying the contact immediately, no messages of farewell or notifications to let the contact know about the disengage\}/g, '{\Disengage with the contact\}')
        },

        reverseTransformListsString(input) {
            // Use regular expression to find all occurrences of "{Add contact to "..." list(s)}"
            const regex = /\{Add\s+\s+contact+s\to+\s"([^"]+)"\s+list\(s\)}/g

            // Replace each occurrence found with the transformed version
            const transformed = input.replace(regex, (match, p1) => {
                // Split lists by " || "
                const items = p1.split('||').map(item => item.trim())

                // Create the new string with square brackets around each item
                const bracketedItems = items.map(item => `[ ${item} ]`).join(' ')

                // Replace the content of the tags in the original string
                return `{Add contact to ${bracketedItems} list(s)}`
            })

            return transformed
        },

        async getTags(ids = []) {
            if (!ids.length) {
                return
            }

            const params = {
                full_load: true,
                tag_ids: ids
            }

            this.loadingTags = true
            return axios.get('/api/v1/tag', {params}).then(res => {
                this.tags = res.data
                this.loadingTags = false
            }).catch(err => {
                console.log(err)
                this.loadingTags = false
            })
        },

        getTagsInCurrentSection(tagsInCurrentSection) {
            // Get the tags ids from tagsInCurrentSection and set tags
            const tagIds = tagsInCurrentSection.map(tag => tag.id)

            this.tags = cloneDeep(tagsInCurrentSection)
            this.currentTagsIds = tagIds
            this.currentTags = cloneDeep(tagsInCurrentSection)
        },

        getListsInCurrentSection(listsInCurrentSection) {
            // Get the list ids from listsInCurrentSection and set lists
            const listIds = listsInCurrentSection.map(item => item.id)

            this.lists = cloneDeep(listsInCurrentSection)
            this.currentListsIds = listIds
            this.currentLists = cloneDeep(listsInCurrentSection)
        },

        updateReadonlySections() {
            // find with either "tag(s) to the contact" or "disposition to the contact" as part of the template
            const regex = /\{Add \[(.*?)\] tag\(s\) to the contact\}|\{Add \((.*?)\) disposition to the contact\}|\{Disengage with the contact\}|\{Add contact to \[(.*?)\] list\(s\)\}/g
            const backup = cloneDeep(this.readonlySections)
            const content = this.bot.instructions[0].content
            this.readonlySections = []

            let match
            // Find any match for the templates {Add [ ... ] tag(s) to the contact} or {Add ( ... ) disposition to the contact}
            while ((match = regex.exec(content)) !== null) {
                if (match[0] === '{Disengage with the contact}') {
                    this.readonlySections.push({
                        start: match.index,
                        end: match.index + match[0].length
                    })
                    continue;
                }

                if (match[1]) {
                    // Template {Add [ ... ] tag(s) to the contact}
                    const tagNames = this.transformTagsString(match[1])
                    const backupSection = backup.find(section => section.tagNames === tagNames)

                    if (backupSection) {
                        this.readonlySections.push({
                            start: match.index,
                            end: match.index + match[0].length,
                            tags: backupSection.tags,
                            tagNames: backupSection.tagNames
                        })
                    } else {
                        this.readonlySections.push({
                            start: match.index,
                            end: match.index + match[0].length,
                            tagNames: tagNames,
                            tags: cloneDeep(this.tags)
                        })
                    }

                    continue
                }

                if (match[2]) {
                    // Template {Add ( ... ) disposition to the contact}
                    let dispositionName = match[2]
                    const backupSection = backup.find(section => section.dispositionName && section.dispositionName.trim() === dispositionName.trim())

                    if (backupSection) {
                        this.readonlySections.push({
                            start: match.index,
                            end: match.index + match[0].length,
                            disposition: backupSection.disposition,
                            dispositionName: backupSection.dispositionName
                        })
                    } else {
                        this.readonlySections.push({
                            start: match.index,
                            end: match.index + match[0].length,
                            dispositionName: dispositionName,
                            disposition: cloneDeep(this.disposition)
                        })
                    }

                    continue
                }

                if (match[3]) {
                    // Template {Add contact to [ ... ] list(s)}
                    const listNames = this.transformListsString(match[3])
                    const backupSection = backup.find(section => section.listNames === listNames)

                    if (backupSection) {
                        this.readonlySections.push({
                            start: match.index,
                            end: match.index + match[0].length,
                            lists: backupSection.lists,
                            listNames: backupSection.listNames
                        })
                    } else {
                        this.readonlySections.push({
                            start: match.index,
                            end: match.index + match[0].length,
                            lists: cloneDeep(this.lists),
                            listNames: listNames,
                        })
                    }

                    continue
                }
            }
        },

        onInput(event) {
            this.updateCursorPosition(event)
            this.updateReadonlySections()
            const newVal = this.bot.instructions[0].content

            if (!newVal || newVal.trim() === "") {
                this.resetTagsDropdown()
                this.resetDispositionsDropdown()
                this.readonlySections = []
            } else {
                // Check if any readonly section has been deleted
                const removed = this.readonlySections.filter(section => {
                    return newVal.slice(section.start + 1, section.end - 1) === section.tagNames
                })

                this.collectSpecialInstructionsToSave()
            }

        },

        collectSpecialInstructionsToSave() {
            // iterate over tags in every element inside this.readonlySections
            // and get an array of names so we can validate in backend endpoint
            const tag_names = []
            const disposition_names = []
            const list_names = []

            this.readonlySections.forEach(section => {
                if (section.tags) {
                    const names = section.tags.map(tag => tag.name)
                    tag_names.push(...names)
                }
                if (section.disposition) {
                    disposition_names.push(section.dispositionName)
                }
                if (section.lists) {
                    const names = section.lists.map(list => list.name)
                    list_names.push(...names)
                }
            })

            this.bot.instructions_tags = tag_names
            this.bot.instructions_dispositions = disposition_names
            this.bot.instructions_lists = list_names
        },

        onKeyDown(event) {
            this.validateForbiddenKeys(event)
            this.validateIfArrowKeysArePresent(event)

            const positionUpdated = this.updatePositioForArrowKeys(event)
            if (positionUpdated) {
                return
            }

            this.validateIfAttemptingToEditSectionContent(event)

            if (event.key === 'Backspace') {
                this.removeSectionCompletely(event)
            }
        },

        validateForbiddenKeys(event) {
            if (['[', ']', '{', '}', '(', ')'].includes(event.key)) {
                this.preventEvent(event)
            }
        },

        validateIfArrowKeysArePresent(event) {
            if (!['ArrowLeft', 'ArrowRight'].includes(event.key)) {
                this.updateCursorPosition(event) // Update cursor position
            }
        },

        updatePositioForArrowKeys(event) {
            // If the key pressed is Arrow Left/Right just update the cursorPosition
            if (event.key === 'ArrowLeft') {
                this.cursorPosition--
                return true
            }

            if (event.key === 'ArrowRight') {
                this.cursorPosition++
                return true
            }

            return false
        },

        validateIfAttemptingToEditSectionContent(event) {
            const selectionEndPosition = event.target.selectionEnd
            const allowedKeys = ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown']

            for (const section of this.readonlySections) {
                const isCursorInSectionContent = this.cursorPosition > section.start && this.cursorPosition < section.end
                const isPartiallySelectingSection = selectionEndPosition > section.start && selectionEndPosition < section.end

                if (isCursorInSectionContent || isPartiallySelectingSection) {
                    if (!allowedKeys.includes(event.key)) {
                        this.preventEvent(event)
                        return
                    }
                }
            }
        },

        removeSectionCompletely(event) {
            for (const section of this.readonlySections) {
                if (this.cursorPosition === section.end) {
                    this.bot.instructions[0].content = this.bot.instructions[0].content.slice(0, section.start) + this.bot.instructions[0].content.slice(section.end)
                    this.$nextTick(() => {
                        event.target.selectionStart = section.start
                        event.target.selectionEnd = section.start
                    })
                    //remove the section
                    this.readonlySections = this.readonlySections.filter(s => s.start !== section.start)
                    this.preventEvent(event)
                    this.resetTagsDropdown()
                    this.resetDispositionsDropdown()
                    this.updateReadonlySections()
                    break
                }
            }
        },

        checkTagsInCurrentSection() {
            let tagsInCurrentSection = []

            for (const section of this.readonlySections) {
                if (section.tags && this.cursorPosition > section.start && this.cursorPosition < section.end) {
                    tagsInCurrentSection = section.tags
                    this.isEditingReadonlySection = true
                    this.currentSection = cloneDeep(section)
                    break
                }
            }

            return tagsInCurrentSection
        },

        checkDispositionInCurrentSection() {
            let found = false

            for (const section of this.readonlySections) {
                if (section.disposition && this.cursorPosition > section.start && this.cursorPosition < section.end) {
                    this.isEditingReadonlySection = true
                    this.currentSection = cloneDeep(section)
                    this.currentDisposition = cloneDeep(section.disposition)
                    found = true
                    break
                }
            }

            return found
        },

        checkContactDisengageInCurrentSection() {
            for (const section of this.readonlySections) {
                if (section.disengageContact && this.cursorPosition > section.start && this.cursorPosition < section.end) {
                    this.isEditingReadonlySection = true
                    this.currentSection = cloneDeep(section)

                    return true
                }
            }

            return false
        },

        checkListsInCurrentSection() {
            let listsInCurrentSection = []

            for (const section of this.readonlySections) {
                if (section.lists && this.cursorPosition > section.start && this.cursorPosition < section.end) {
                    listsInCurrentSection = section.lists
                    this.isEditingReadonlySection = true
                    this.currentSection = cloneDeep(section)
                    break
                }
            }

            return listsInCurrentSection
        },

        handlePaste(event) {
            // Prevent default paste action
            this.preventEvent(event)

            // Get clipboard content
            const clipboardData = (event.clipboardData || window.clipboardData).getData('text')

            // Remove illegal characters
            const sanitizedData = clipboardData.replace(/[{}\[\]\(\)]/g, '')

            // Get the current cursor position
            const textarea = this.$refs.textareaInput.$el.querySelector('textarea')
            const startPos = textarea.selectionStart
            const endPos = textarea.selectionEnd

            // Insert the sanitized content at the current cursor position
            const currentValue = this.bot.instructions[0].content
            this.bot.instructions[0].content = currentValue.substring(0, startPos) + sanitizedData + currentValue.substring(endPos)

            // Adjust cursor position after pasting
            this.$nextTick(() => {
                textarea.setSelectionRange(startPos + sanitizedData.length, startPos + sanitizedData.length)
            })
        },

        handleClick(event) {
            this.updateCursorPosition(event)
        },

        updateCursorPosition(event) {
            this.cursorPosition = event.target.selectionStart
        },

        preventEvent(event) {
            event.preventDefault()
        },

        resetTagsDropdown() {
            this.tagsDropdownKey += 1
            this.currentTagsIds = []
            this.currentTags = []
        },

        resetDispositionsDropdown() {
            this.dispositionsDropdownKey += 1
            this.currentDisposition = {}
        },

        async addDisengageTrigger() {
            if (this.isEditingReadonlySection) {
                return
            }
            const disengageInstruction = '{Disengage with the contact}';
            let newContent = this.bot.instructions[0].content

            // Check if cursor is within any readonly section
            let updated = false
            const updatedSectionData = this.updateReadOnlyDisengageContactSection(newContent)
            updated = updatedSectionData.isUpdated
            newContent = updatedSectionData.newContent

            // If no section was updated, insert the tags at the cursor position
            if (!updated) {
                this.isEditingReadonlySection = true
                newContent = newContent.slice(0, this.cursorPosition) + disengageInstruction + newContent.slice(this.cursorPosition)
                const endSection = this.cursorPosition + disengageInstruction.length
                const section = {
                    start: this.cursorPosition,
                    end: endSection,
                    disengageContact: true,
                }
                // Update readonlySections with the new section
                this.readonlySections.push(section)
                this.cursorPosition = endSection
                this.currentSection = cloneDeep(section)

                // Adjust the start and end positions of the following sections
                this.adjustReadonlySections(this.currentSection.start, disengageInstruction.length)
            }

            this.bot.instructions[0].content = newContent
        },

        emitValidate() {
            this.$emit('validate')
        },

        variableSelected(variable) {
            this.bot.opener += `${variable}`
        },
    },

    watch: {
        bot(newVal) {
            if (newVal.uploaded_files && newVal.uploaded_files.length) {
                this.uploaded_files = newVal.uploaded_files
            }

            if (newVal.readonly_sections && newVal.readonly_sections.length) {
                this.readonlySections = newVal.readonly_sections
                delete this.bot.readonly_sections

                // check if inside we found \" and replace them with '[' or ']' respectively
                // to manage tags content inside instructions
                this.bot.instructions[0].content = this.reverseTransformTagsString(this.bot.instructions[0].content)
                this.bot.instructions[0].content = this.reverseTransformDispositionsString(this.bot.instructions[0].content)
                this.bot.instructions[0].content = this.reverseTransformDisengageContact(this.bot.instructions[0].content)
                this.bot.instructions[0].content = this.reverseTransformListsString(this.bot.instructions[0].content)

                this.updateReadonlySections()
                this.collectSpecialInstructionsToSave()
            }
        },

        async cursorPosition(newVal, oldVal) {
            const tagsInCurrentSection = this.checkTagsInCurrentSection()
            if (tagsInCurrentSection.length) {
                this.resetDispositionsDropdown()
                this.getTagsInCurrentSection(tagsInCurrentSection)
                return
            }

            const dispositionInCurrentSection = this.checkDispositionInCurrentSection()
            if (dispositionInCurrentSection) {
                this.resetTagsDropdown()
                return
            }

            const disengageContactInCurrentSection = this.checkContactDisengageInCurrentSection()
            if (disengageContactInCurrentSection) {
                return
            }

            const listsInCurrentSection = this.checkListsInCurrentSection()
            if (listsInCurrentSection.length) {
                this.getListsInCurrentSection(listsInCurrentSection)
                return
            }

            if (this.currentSection && (oldVal >= this.currentSection.start && oldVal <= this.currentSection.end) && (newVal < this.currentSection.start || newVal > this.currentSection.end)) {
                this.currentSection = {}
                this.isEditingReadonlySection = false
            }
        },

        isEditingReadonlySection(newVal, oldVal) {
            if (oldVal && !newVal) {
                this.resetTagsDropdown()
                this.resetDispositionsDropdown()
            }
        }
    },

    mounted() {
        this.updateReadonlySections()

        // Access the textarea element inside el-input
        const textarea = this.$refs.textareaInput.$el.querySelector('textarea')
        if (textarea) {
            textarea.addEventListener('keydown', this.onKeyDown)
            textarea.addEventListener('click', this.handleClick)
            textarea.addEventListener('keyup', this.updateCursorPosition)
            textarea.addEventListener('input', this.onInput)
            textarea.addEventListener('paste', this.handlePaste)
        }
    },

    beforeDestroy() {
        // Cleanup event listener
        const textarea = this.$refs.textareaInput.$el.querySelector('textarea')
        if (textarea) {
            textarea.removeEventListener('keydown', this.onKeyDown)
            textarea.removeEventListener('click', this.handleClick)
            textarea.removeEventListener('keyup', this.updateCursorPosition)
            textarea.removeEventListener('input', this.onInput)
            textarea.removeEventListener('paste', this.handlePaste)
        }
    }
}
</script>
