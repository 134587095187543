var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    {
      ref: "companySettingsForm",
      staticClass: "form-aloware",
      attrs: { model: _vm.report_setting, rules: _vm.rules },
      nativeOn: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submitForm("companySettingsForm")
        },
      },
    },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-7" },
          [
            _c(
              "el-form-item",
              { attrs: { prop: "default_report_period" } },
              [
                _c("span", {
                  staticClass: "page-anchor",
                  attrs: { id: "default_report_period" },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "form-label" }, [
                  _c("h5", [_vm._v("Default Report Period")]),
                  _vm._v(" "),
                  _c("small", [
                    _vm._v(
                      "Configure how the default timeframe for the dashboard and its main graph."
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "el-select",
                  {
                    staticClass: "w-full",
                    on: {
                      change: function ($event) {
                        return _vm.updateParent("default_report_period")
                      },
                    },
                    model: {
                      value: _vm.report_setting.default_report_period,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.report_setting,
                          "default_report_period",
                          $$v
                        )
                      },
                      expression: "report_setting.default_report_period",
                    },
                  },
                  [
                    _c("el-option", { attrs: { value: "day" } }),
                    _vm._v(" "),
                    _c("el-option", { attrs: { value: "week" } }),
                    _vm._v(" "),
                    _c("el-option", { attrs: { value: "month" } }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("el-form-item", [
              _c("span", {
                staticClass: "page-anchor",
                attrs: { id: "daily_activity_reports_enabled" },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "form-label" }, [
                _c("h5", [_vm._v("Daily Activity Reports")]),
                _vm._v(" "),
                _c("small", [
                  _vm._v(
                    "\n                        Send daily reports of calls and texts, new lead dispositions,\n                        and agents activities by email. This report is emailed to\n                        account admins and includes unreturned statistics. "
                  ),
                  _c("br"),
                  _vm._v(
                    "\n                        An unreturned conversation is any contact that has an unread communication or is without a response."
                  ),
                  _c("br"),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "is-link",
                      attrs: {
                        href: "https://support.aloware.com/en/articles/9034116-aloware-s-unreturned-conversations-your-key-to-unmissed-opportunities",
                        target: "_blank",
                      },
                    },
                    [
                      _c("i", { staticClass: "material-icons text-lg" }, [
                        _vm._v("help"),
                      ]),
                      _vm._v(
                        "\n                           Click here to learn more\n                        "
                      ),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "switch-label" },
                [
                  _c("el-switch", {
                    attrs: { "active-color": "#00a344" },
                    on: {
                      change: function ($event) {
                        return _vm.updateParent(
                          "daily_activity_reports_enabled"
                        )
                      },
                    },
                    model: {
                      value: _vm.report_setting.daily_activity_reports_enabled,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.report_setting,
                          "daily_activity_reports_enabled",
                          $$v
                        )
                      },
                      expression:
                        "report_setting.daily_activity_reports_enabled",
                    },
                  }),
                  _vm._v(" "),
                  _c("label", [_vm._v("Enable daily activity reports")]),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "form-label mt-2" }, [
                _c("small", [
                  _vm._v(
                    "\n                        By selecting this box, all phone extension metrics will be included in the report.\n                    "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "el-checkbox",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.report_setting.daily_activity_reports_enabled,
                          expression:
                            "report_setting.daily_activity_reports_enabled",
                        },
                      ],
                      staticClass: "mr-2 mt-2",
                      attrs: { border: "" },
                      on: {
                        change: function ($event) {
                          return _vm.updateParent(
                            "send_daily_activity_reports_with_user_extensions"
                          )
                        },
                      },
                      model: {
                        value:
                          _vm.report_setting
                            .send_daily_activity_reports_with_user_extensions,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.report_setting,
                            "send_daily_activity_reports_with_user_extensions",
                            $$v
                          )
                        },
                        expression:
                          "report_setting.send_daily_activity_reports_with_user_extensions",
                      },
                    },
                    [_c("strong", [_vm._v("Include extensions")])]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "form-label mt-2" }, [
                _c("small", [
                  _vm._v(
                    "\n                        By selecting this box, only users with activity will be included in the report.\n                    "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "el-checkbox",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.report_setting.daily_activity_reports_enabled,
                          expression:
                            "report_setting.daily_activity_reports_enabled",
                        },
                      ],
                      staticClass: "mr-2 mt-2",
                      attrs: { border: "" },
                      on: {
                        change: function ($event) {
                          return _vm.updateParent(
                            "send_daily_activity_reports_hiding_users_without_activity"
                          )
                        },
                      },
                      model: {
                        value:
                          _vm.report_setting
                            .send_daily_activity_reports_hiding_users_without_activity,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.report_setting,
                            "send_daily_activity_reports_hiding_users_without_activity",
                            $$v
                          )
                        },
                        expression:
                          "report_setting.send_daily_activity_reports_hiding_users_without_activity",
                      },
                    },
                    [_c("strong", [_vm._v("Hide Users Without Activity")])]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "el-form-item",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.report_setting.daily_activity_reports_enabled,
                    expression: "report_setting.daily_activity_reports_enabled",
                  },
                ],
                attrs: { prop: "send_daily_activity_reports_on" },
              },
              [
                _c("span", {
                  staticClass: "page-anchor",
                  attrs: { id: "send_daily_activity_reports_on" },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "form-label" }, [
                  _c("h5", [_vm._v("Send Activity Reports On")]),
                  _vm._v(" "),
                  _c("small", [
                    _vm._v(
                      "When to send the report, in your company timezone."
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "el-select",
                  {
                    staticClass: "w-full",
                    attrs: { filterable: "", placeholder: "Select time" },
                    on: {
                      change: function ($event) {
                        return _vm.updateParent(
                          "send_daily_activity_reports_on"
                        )
                      },
                    },
                    model: {
                      value: _vm.report_setting.send_daily_activity_reports_on,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.report_setting,
                          "send_daily_activity_reports_on",
                          $$v
                        )
                      },
                      expression:
                        "report_setting.send_daily_activity_reports_on",
                    },
                  },
                  _vm._l(_vm.everyThirtyMinutes, function (time) {
                    return _c("el-option", {
                      key: time.value,
                      attrs: { label: time.label, value: time.value },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.report_setting.daily_activity_reports_enabled,
                    expression: "report_setting.daily_activity_reports_enabled",
                  },
                ],
              },
              [
                _c("span", {
                  staticClass: "page-anchor",
                  attrs: { id: "send_daily_activity_reports_for" },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "form-label" }, [
                  _c("h5", [_vm._v("Send Activity Reports For")]),
                  _vm._v(" "),
                  _c("small", [
                    _vm._v(
                      "Receive report from the beginning of the day (00:00 am) or the previous cut off."
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "el-radio-group",
                  {
                    on: {
                      change: function ($event) {
                        return _vm.updateParent(
                          "send_daily_activity_reports_for"
                        )
                      },
                    },
                    model: {
                      value: _vm.report_setting.send_daily_activity_reports_for,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.report_setting,
                          "send_daily_activity_reports_for",
                          $$v
                        )
                      },
                      expression:
                        "report_setting.send_daily_activity_reports_for",
                    },
                  },
                  [
                    _c("el-radio-button", { attrs: { label: 1 } }, [
                      _vm._v("Today"),
                    ]),
                    _vm._v(" "),
                    _c("el-radio-button", { attrs: { label: 2 } }, [
                      _vm._v("Last 24 hours"),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "bg-deep-orange mt-2",
                        attrs: {
                          type: "warning",
                          size: "small",
                          loading: _vm.test_activity_report.loading,
                        },
                        on: { click: _vm.sendActivityReports },
                      },
                      [
                        _vm._v(
                          "\n                        Test daily activity reports\n                    "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("el-form-item", [
              _c("span", {
                staticClass: "page-anchor",
                attrs: { id: "set_reports_recipients" },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "form-label mb-3" }, [
                _c("h5", [_vm._v("System Reports Recipients")]),
                _vm._v(" "),
                _c("small", [
                  _vm._v(
                    "Assign roles that will receive reports and notifications for the following activities:"
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "table",
                { staticClass: "table table-bordered table-hover table-sm" },
                [
                  _c("thead", [
                    _c(
                      "tr",
                      [
                        _c("th"),
                        _vm._v(" "),
                        _vm._l(_vm.AvailableReportingRoles, function (role) {
                          return _c("th", { staticClass: "text-center" }, [
                            _vm._v(
                              "\n                                " +
                                _vm._s(role) +
                                "\n                            "
                            ),
                          ])
                        }),
                      ],
                      2
                    ),
                  ]),
                  _vm._v(" "),
                  _c("tbody", [
                    _c(
                      "tr",
                      [
                        _c("td", { attrs: { valign: "middle" } }, [
                          _c("div", { staticClass: "w" }, [
                            _c("strong", [_vm._v("Billing Warnings")]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.AvailableReportingRoles, function (role) {
                          return _c(
                            "td",
                            {
                              staticClass: "text-center",
                              attrs: { valign: "middle" },
                            },
                            [
                              _c("el-checkbox", {
                                key: `billing-reports-role-${_vm.stripSpaces(
                                  role
                                )}`,
                                staticClass: "el-checkbox-height-fix",
                                attrs: {
                                  checked: _vm.reportingHasRole(
                                    _vm.ReportingRoles.BILLING_WARNINGS,
                                    role
                                  ),
                                  disabled:
                                    _vm.isBillingWarningsRoleCheckboxDisabled(
                                      role
                                    ),
                                },
                                on: {
                                  change: (checked) =>
                                    _vm.onChangeReportingRole(
                                      checked,
                                      _vm.ReportingRoles.BILLING_WARNINGS,
                                      role
                                    ),
                                },
                              }),
                            ],
                            1
                          )
                        }),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "tr",
                      [
                        _c("td", { attrs: { valign: "middle" } }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-content-between w-lg",
                            },
                            [
                              _c("strong", [_vm._v("Integration Reports")]),
                              _vm._v(" "),
                              _c(
                                "el-popover",
                                {
                                  attrs: {
                                    width: "230",
                                    trigger: "hover",
                                    placement: "right",
                                    "data-testid":
                                      "integration-reports-popover",
                                  },
                                },
                                [
                                  _c("div", [
                                    _c(
                                      "div",
                                      { staticClass: "pb-2 font-weight-bold" },
                                      [_vm._v("Integration Reports include:")]
                                    ),
                                    _vm._v(" "),
                                    _c("ul", { staticClass: "mb-0" }, [
                                      _c("li", [_vm._v("Users Sync")]),
                                      _vm._v(" "),
                                      _c("li", [_vm._v("Contacts Sync")]),
                                      _vm._v(" "),
                                      _c("li", [
                                        _vm._v("Contact Dispositions Sync"),
                                      ]),
                                      _vm._v(" "),
                                      _c("li", [
                                        _vm._v("Call Dispositions Sync"),
                                      ]),
                                      _vm._v(" "),
                                      _c("li", [
                                        _vm._v(
                                          "Failed Integration Connection Status"
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      attrs: { slot: "reference" },
                                      slot: "reference",
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "fa fa-info-circle text-primary",
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.AvailableReportingRoles, function (role) {
                          return _c(
                            "td",
                            {
                              staticClass: "text-center",
                              attrs: { valign: "middle" },
                            },
                            [
                              _c("el-checkbox", {
                                key: `integration-reports-role-${_vm.stripSpaces(
                                  role
                                )}`,
                                staticClass: "el-checkbox-height-fix",
                                attrs: {
                                  checked: _vm.reportingHasRole(
                                    _vm.ReportingRoles.INTEGRATION_REPORTS,
                                    role
                                  ),
                                },
                                on: {
                                  change: (checked) =>
                                    _vm.onChangeReportingRole(
                                      checked,
                                      _vm.ReportingRoles.INTEGRATION_REPORTS,
                                      role
                                    ),
                                },
                              }),
                            ],
                            1
                          )
                        }),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "tr",
                      [
                        _c("td", { attrs: { valign: "middle" } }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-content-between w-lg",
                            },
                            [
                              _c("strong", [_vm._v("Account Reports")]),
                              _vm._v(" "),
                              _c(
                                "el-popover",
                                {
                                  attrs: {
                                    width: "230",
                                    trigger: "hover",
                                    placement: "right",
                                    "data-testid":
                                      "integration-reports-popover",
                                  },
                                },
                                [
                                  _c("div", [
                                    _c(
                                      "div",
                                      { staticClass: "pb-2 font-weight-bold" },
                                      [_vm._v("Account Reports include:")]
                                    ),
                                    _vm._v(" "),
                                    _c("ul", { staticClass: "mb-0" }, [
                                      _c("li", [
                                        _vm._v("Daily Analytics Reports"),
                                      ]),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      attrs: { slot: "reference" },
                                      slot: "reference",
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "fa fa-info-circle text-primary",
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.AvailableReportingRoles, function (role) {
                          return _c(
                            "td",
                            {
                              staticClass: "text-center",
                              attrs: { valign: "middle" },
                            },
                            [
                              _c("el-checkbox", {
                                key: `account-reports-role-${_vm.stripSpaces(
                                  role
                                )}`,
                                staticClass: "el-checkbox-height-fix",
                                attrs: {
                                  checked: _vm.reportingHasRole(
                                    _vm.ReportingRoles.ACCOUNT_REPORTS,
                                    role
                                  ),
                                },
                                on: {
                                  change: (checked) =>
                                    _vm.onChangeReportingRole(
                                      checked,
                                      _vm.ReportingRoles.ACCOUNT_REPORTS,
                                      role
                                    ),
                                },
                              }),
                            ],
                            1
                          )
                        }),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _vm.showAloAiReportsSettings
                      ? _c(
                          "tr",
                          [
                            _c("td", { attrs: { valign: "middle" } }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-content-between w-lg",
                                },
                                [
                                  _c("strong", [_vm._v("AloAi Reports")]),
                                  _vm._v(" "),
                                  _c(
                                    "el-popover",
                                    {
                                      attrs: {
                                        width: "230",
                                        trigger: "hover",
                                        placement: "right",
                                        "data-testid":
                                          "integration-reports-popover",
                                      },
                                    },
                                    [
                                      _c("div", [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "pb-2 font-weight-bold",
                                          },
                                          [_vm._v("AloAi Reports include:")]
                                        ),
                                        _vm._v(" "),
                                        _c("ul", { staticClass: "mb-0" }, [
                                          _c("li", [
                                            _vm._v(
                                              "AloAi Voice Analytics Reports"
                                            ),
                                          ]),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          attrs: { slot: "reference" },
                                          slot: "reference",
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "fa fa-info-circle text-primary",
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _vm._l(
                              _vm.AvailableReportingRoles,
                              function (role) {
                                return _c(
                                  "td",
                                  {
                                    staticClass: "text-center",
                                    attrs: { valign: "middle" },
                                  },
                                  [
                                    _c("el-checkbox", {
                                      key: `account-reports-role-${_vm.stripSpaces(
                                        role
                                      )}`,
                                      staticClass: "el-checkbox-height-fix",
                                      attrs: {
                                        checked: _vm.reportingHasRole(
                                          _vm.ReportingRoles.ALOAI_REPORTS,
                                          role
                                        ),
                                      },
                                      on: {
                                        change: (checked) =>
                                          _vm.onChangeReportingRole(
                                            checked,
                                            _vm.ReportingRoles.ALOAI_REPORTS,
                                            role
                                          ),
                                      },
                                    }),
                                  ],
                                  1
                                )
                              }
                            ),
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "tr",
                      [
                        _c("td", { attrs: { valign: "middle" } }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-content-between w-lg",
                            },
                            [_c("strong", [_vm._v("Import/Export Reports")])]
                          ),
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.AvailableReportingRoles, function (role) {
                          return _c(
                            "td",
                            {
                              staticClass: "text-center",
                              attrs: { valign: "middle" },
                            },
                            [
                              _c("el-checkbox", {
                                key: `import-export-reports-role-${_vm.stripSpaces(
                                  role
                                )}`,
                                staticClass: "el-checkbox-height-fix",
                                attrs: {
                                  checked: _vm.reportingHasRole(
                                    _vm.ReportingRoles.IMPORT_EXPORT_REPORTS,
                                    role
                                  ),
                                },
                                on: {
                                  change: (checked) =>
                                    _vm.onChangeReportingRole(
                                      checked,
                                      _vm.ReportingRoles.IMPORT_EXPORT_REPORTS,
                                      role
                                    ),
                                },
                              }),
                            ],
                            1
                          )
                        }),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "tr",
                      [
                        _c("td", { attrs: { valign: "middle" } }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-content-between w-lg",
                            },
                            [
                              _c("strong", [
                                _vm._v("Webhook Failure Notifications"),
                              ]),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.AvailableReportingRoles, function (role) {
                          return _c(
                            "td",
                            {
                              staticClass: "text-center",
                              attrs: { valign: "middle" },
                            },
                            [
                              _c("el-checkbox", {
                                key: `webhook-failure-notifications-role-${_vm.stripSpaces(
                                  role
                                )}`,
                                staticClass: "el-checkbox-height-fix",
                                attrs: {
                                  checked: _vm.reportingHasRole(
                                    _vm.ReportingRoles
                                      .WEBHOOK_FAILURE_NOTIFICATIONS,
                                    role
                                  ),
                                },
                                on: {
                                  change: (checked) =>
                                    _vm.onChangeReportingRole(
                                      checked,
                                      _vm.ReportingRoles
                                        .WEBHOOK_FAILURE_NOTIFICATIONS,
                                      role
                                    ),
                                },
                              }),
                            ],
                            1
                          )
                        }),
                      ],
                      2
                    ),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c(
              "el-form-item",
              {
                staticClass: "no-border pb-3",
                attrs: { prop: "qualified_calls" },
              },
              [
                _c("span", {
                  staticClass: "page-anchor",
                  attrs: { id: "qualified_calls" },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "form-label" }, [
                  _c("h5", [_vm._v("Qualified Calls")]),
                  _vm._v(" "),
                  _c("small", [
                    _vm._v(
                      "\n                        Set the criteria used on the reports to determine what is a qualified call. This value will be used on the Reports tab and the Dashboard.\n                    "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "font-weight-bold mr-2" }, [
                      _vm._v("Threshold 1"),
                    ]),
                    _vm._v(" "),
                    _c("el-input-number", {
                      attrs: { min: 0, max: 1440 },
                      on: {
                        change: function ($event) {
                          return _vm.updateParent("qualified_calls_threshold1")
                        },
                      },
                      model: {
                        value: _vm.report_setting.qualified_calls_threshold1,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.report_setting,
                            "qualified_calls_threshold1",
                            $$v
                          )
                        },
                        expression: "report_setting.qualified_calls_threshold1",
                      },
                    }),
                    _vm._v(" "),
                    _c("label", { staticClass: "ml-1" }, [_vm._v("Minutes")]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "font-weight-bold mr-2" }, [
                      _vm._v("Threshold 2"),
                    ]),
                    _vm._v(" "),
                    _c("el-input-number", {
                      attrs: { min: 0, max: 1440 },
                      on: {
                        change: function ($event) {
                          return _vm.updateParent("qualified_calls_threshold2")
                        },
                      },
                      model: {
                        value: _vm.report_setting.qualified_calls_threshold2,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.report_setting,
                            "qualified_calls_threshold2",
                            $$v
                          )
                        },
                        expression: "report_setting.qualified_calls_threshold2",
                      },
                    }),
                    _vm._v(" "),
                    _c("label", { staticClass: "ml-1" }, [_vm._v("Minutes")]),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-2 d-none d-md-block ml-auto" }, [
          _c(
            "div",
            {
              staticClass:
                "list-group list-group-alo list-group-flush sticky-top stick-top-with-header top-70",
            },
            [
              _c("h6", { staticClass: "list-group-item" }, [
                _vm._v("\n                    On This Page\n                "),
              ]),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "list-group-item list-group-item-action",
                  attrs: { href: "#default_report_period" },
                  on: {
                    click: function ($event) {
                      return _vm.shine("default_report_period")
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                    Default Report Period\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "list-group-item list-group-item-action",
                  attrs: { href: "#daily_activity_reports_enabled" },
                  on: {
                    click: function ($event) {
                      return _vm.shine("daily_activity_reports_enabled")
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                    Daily Activity Reports\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.report_setting.daily_activity_reports_enabled,
                      expression:
                        "report_setting.daily_activity_reports_enabled",
                    },
                  ],
                  staticClass: "list-group-item list-group-item-action",
                  attrs: { href: "#send_daily_activity_reports_on" },
                  on: {
                    click: function ($event) {
                      return _vm.shine("send_daily_activity_reports_on")
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                    Send Activity Reports On\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.report_setting.daily_activity_reports_enabled,
                      expression:
                        "report_setting.daily_activity_reports_enabled",
                    },
                  ],
                  staticClass: "list-group-item list-group-item-action",
                  attrs: { href: "#send_daily_activity_reports_for" },
                  on: {
                    click: function ($event) {
                      return _vm.shine("send_daily_activity_reports_for")
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                    Send Activity Reports For\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "list-group-item list-group-item-action",
                  attrs: { href: "#set_reports_recipients" },
                  on: {
                    click: function ($event) {
                      return _vm.shine("set_reports_recipients")
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                    System Reports Recipients\n                "
                  ),
                ]
              ),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }