<template>
    <div>
        <div class="mb-3"
             v-if="canAddExtensionStep">
            <el-button type="primary"
                       @click="addExtensionStep">
                <i class="material-icons">headset_mic</i>
                <span class="_700 pointer">Listen For Extensions?</span>
            </el-button>
        </div>
        <transition-group name="ivr-list">
            <el-card class="box-card mb-3 ivr-step"
                     :key="ivr_step.id"
                     v-if="!ivr_step.type || ivr_step.type !== 'exit'"
                     v-for="(ivr_step, idx) in steps">
                <div slot="header"
                     class="clearfix">
                    <template v-if="!ivr_step.listen_for_extension">
                        <span class="text-md _600">#{{ ivr_step.key }} - IVR Option</span>
                        <el-button class="pull-right text-danger"
                                   type="text"
                                   @click="removeAt(idx)">
                            <i class="material-icons">delete</i>
                            Delete
                        </el-button>
                        <el-button class="pull-right text-blue mr-3"
                                   type="text"
                                   @click="duplicate(ivr_step, idx)">
                            <i class="material-icons">content_copy</i>
                            Duplicate
                        </el-button>
                    </template>
                    <template v-else>
                        <span class="text-md _600">Listen For Extensions</span>
                        <el-button class="pull-right text-danger"
                                   type="text"
                                   @click="removeAt(idx)">
                            <i class="material-icons">delete</i>
                            Delete
                        </el-button>
                    </template>
                </div>
                <campaign-ivr-step :campaign="campaign"
                                   :ivr_step="ivr_step"
                                   :used_keys="usedKeys">
                </campaign-ivr-step>
            </el-card>
        </transition-group>
        <el-card class="box-card mb-3">
            <div slot="header"
                 class="clearfix">
                <span class="text-md _600">IVR Exit</span>
            </div>
            <campaign-ivr-step :campaign="campaign"
                               :ivr_step="exitStep"
                               :used_keys="usedKeys">
            </campaign-ivr-step>
        </el-card>
        <div class="mt-3">
            <el-button type="primary"
                       @click="addStep">
                <i class="material-icons">add</i>
                <span class="_700 pointer">Add Step</span>
            </el-button>
        </div>
    </div>
</template>

<script>
    import auth from '../auth'
    import {acl_mixin, validator_mixin} from '../mixins'
    import {mapState} from 'vuex'
    import CampaignIvrStep from './campaign-ivr-step'

    export default {
        mixins: [validator_mixin, acl_mixin],

        components: {CampaignIvrStep},

        props: {
            campaign: {
                required: true
            },

            ivr_steps: {
                required: true
            },
        },

        data() {
            return {
                auth: auth,
                fixed_steps: []
            }
        },

        computed: {
            ...mapState('cache', ['current_company']),

            usedKeys() {
                if (this.fixed_steps) {
                    return this.fixed_steps.map(ivr_step => (ivr_step.key) ? ivr_step.key : null).filter(o => o !== null)
                }

                return []
            },

            canAddExtensionStep() {
                if (this.fixed_steps) {
                    return !this.fixed_steps.some(ivr_step => ivr_step.listen_for_extension)
                }

                return true
            },

            exitStep() {
                if (this.fixed_steps) {
                    return this.fixed_steps.find(ivr_step => ivr_step.type && ivr_step.type === 'exit')
                }

                return null
            },

            steps() {
                return this.fixed_steps
                    .sort((a, b) => {
                        if (!a.key || !b.key) {
                            return 0
                        }
                        return a.key > b.key ? 1 : -1
                    })
            }
        },

        created() {
            this.fixed_steps = this.ivr_steps
            if (this.fixed_steps && !this.fixed_steps.find(ivr_step => ivr_step.type && ivr_step.type == 'exit')) {
                let ivr_step = {
                    id: this.$uuid.v4(),
                    listen_for_extension: false,
                    key: null,
                    message_tts: '',
                    message_file: null,
                    task: 'replay',
                    reroute_to_campaign_id: null,
                    reroute_to_ivr_id: null,
                    route_to_ring_group_id: null,
                    route_to_user_id: null,
                    route_to_aloai_agent_id: null,
                    call_router_behavior: null,
                    workflow_ids: [],
                    tag_ids: [],
                    type: 'exit',
                    valid: false
                }
                // push new data to end of an array
                this.fixed_steps.push(ivr_step)
            }
        },

        methods: {
            addStep() {
                let ivr_step = {
                    id: this.$uuid.v4(),
                    listen_for_extension: false,
                    key: null,
                    message_tts: '',
                    message_file: null,
                    task: null,
                    reroute_to_campaign_id: null,
                    reroute_to_ivr_id: null,
                    route_to_ring_group_id: null,
                    route_to_user_id: null,
                    route_to_aloai_agent_id: null,
                    call_router_behavior: null,
                    route_to_hs_deal_contact_owner: null,
                    fallback_ring_group_id: null,
                    workflow_ids: [],
                    tag_ids: [],
                    type: 'normal',
                    valid: false
                }
                // push new data to end of an array
                this.fixed_steps.push(ivr_step)
            },

            addExtensionStep() {
                let ivr_step = {
                    id: this.$uuid.v4(),
                    listen_for_extension: true,
                    key: null,
                    message_tts: null,
                    message_file: null,
                    task: 'route_to_extension',
                    reroute_to_campaign_id: null,
                    reroute_to_ivr_id: null,
                    route_to_ring_group_id: null,
                    route_to_user_id: null,
                    route_to_aloai_agent_id: null,
                    call_router_behavior: null,
                    workflow_ids: [],
                    tag_ids: [],
                    type: 'normal',
                    valid: false
                }
                // push new data to beginning of an array
                this.fixed_steps.unshift(ivr_step)
            },

            removeAt(idx) {
                this.fixed_steps.splice(idx, 1)
            },

            duplicate(ivr_step, idx) {
                let duplicated_step = {
                    id: this.$uuid.v4(),
                    listen_for_extension: false,
                    key: ivr_step.key,
                    message_tts: '',
                    message_file: null,
                    task: ivr_step.task,
                    reroute_to_campaign_id: null,
                    reroute_to_ivr_id: null,
                    route_to_ring_group_id: null,
                    route_to_user_id: null,
                    route_to_aloai_agent_id: null,
                    call_router_behavior: null,
                    workflow_ids: [],
                    tag_ids: [],
                    type: 'normal',
                    valid: false
                }

                this.fixed_steps.splice(idx + 1, 0, duplicated_step)
            }
        },

        watch: {
            fixed_steps : {
                deep: true,
                handler: function () {
                    this.ivr_steps = this.fixed_steps
                    this.$emit('updateIvrSteps',this.fixed_steps)
                }
            },
        }
    }
</script>

<style scoped>
.ivr-step {
    transition: all .5s;
}

.ivr-list-leave-active {
    position: absolute;
}
</style>
